<template>
  <div class="">
    <Header></Header>

    <div class="page-container">
      <QuickView></QuickView>

      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <!-- Header -->
          <div class="page-header">
            <!-- Title -->
            <h2
              class="header-title text-capitalize"
              :class="locale == 'ar' ? 'dir-rtl go-right' : ''"
            >
              <i class="mdi mdi-cart"></i>
              {{ $t("projects.pgDonations") }}
            </h2>
            <router-link
              :to="{ name: ref }"
              class="btn btn-primary btn-rounded btn-bold btn-opacity"
              >Back
            </router-link>

            <!-- Role -->
            <label
              class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right"
            >
              {{ auth.role }}
            </label>
            <!-- End Role -->

            <div class="clearfix"></div>

            <!-- Breadcrumb -->
            <nav
              class="breadcrumb breadcrumb-dash"
              :class="locale == 'ar' ? 'dir-rtl' : ''"
            >
              <router-link :to="{ name: 'dashboard' }" class="breadcrumb-item">
                <i class="ti-home p-r-5"></i>
                {{ $t("app.dashboard") }}
              </router-link>
              <router-link
                :to="{ name: ref }"
                class="breadcrumb-item text-capitalize"
                >{{ $t("projects.pgDonations") }}
              </router-link>
              <span class="breadcrumb-item active" style="font-family: arial">{{
                row.invoice_id
              }}</span>
            </nav>
            <!-- End Breadcrumb -->
          </div>
          <!-- End Header -->

          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="loader loader-lg ui-mtop-15"></div>
            </div>
          </div>

          <form
            v-if="!pgLoading"
            @submit.prevent="reply"
            enctype="multipart/form-data"
          >
            <div class="row" :class="locale == 'ar' ? 'dir-rtl' : ''">
              <div class="col-md-12">
                <div class="card">
                  <div class="row">
                    <div class="card-body">
                      <div v-if="row.is_anonymous == 1" class="text-center">
                        <h3>فاعل خير</h3>
                        <div class="col-md-11 ml-auto mr-auto row">
                          <div class="form-group">
                            <label class="control-label">{{
                              $t("projects.mobile")
                            }}</label>
                            <input
                              type="text"
                              disabled=""
                              class="form-control"
                              v-model="row.mobile"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-if="row.is_anonymous == 0" class="row">
                        <div class="col-md-11 ml-auto mr-auto row">
                          <div class="p-v-20 col-6">
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.clientName")
                              }}</label>
                              <input
                                type="text"
                                disabled=""
                                class="form-control"
                                v-model="row.name"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.email")
                              }}</label>
                              <input
                                type="text"
                                disabled=""
                                class="form-control"
                                v-model="row.email"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.mobile")
                              }}</label>
                              <input
                                type="text"
                                disabled=""
                                class="form-control"
                                v-model="row.mobile"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.notes")
                              }}</label>
                              <textarea
                                style="width: 190#"
                                disabled=""
                                class="form-control"
                                v-model="row.notes"
                              ></textarea>
                            </div>
                          </div>
                          <div class="p-v-20 col-6">
                            <label class="control-label">{{
                              $t("projects.image")
                            }}</label>
                            <p><img :src="row.image" style="width: 250px" /></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-11 ml-auto mr-auto row">
                        <div class="p-v-20 col-12">
                          <div class="form-group">
                            <div class="table-responsive">
                              <table
                                class="table"
                                :class="locale == 'ar' ? 'dir-rtl' : ''"
                              >
                                <thead>
                                  <tr class="top-border-none">
                                    <th style="width: 5%">#</th>
                                    <th
                                      scope="col"
                                      class="text-center"
                                      style="width: 25%"
                                    >
                                      {{ $t("projects.image") }}
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-center"
                                      style="width: 25%"
                                    >
                                      {{ $t("projects.name") }}
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-center"
                                      style="width: 25%"
                                    >
                                      {{ $t("projects.amount") }}
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-center"
                                      style="width: 20%"
                                    >
                                      {{ $t("projects.date") }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(cart, index) in row.orders"
                                    :key="index"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td class="text-center">
                                      <img
                                        v-if="cart.image"
                                        :src="cart.image"
                                        style="width: 100px; height: 100px"
                                      />
                                      <img
                                        v-if="!cart.image"
                                        src="/assets/images/logo/children-charity-logo.png"
                                        style="max-width: 200px"
                                      />
                                    </td>
                                    <td class="text-center">
                                      <span v-if="cart.name">{{
                                        cart.name
                                      }}</span>
                                      <span v-if="cart.type">
                                        {{ cart.type.name }}</span
                                      >
                                    </td>
                                    <td class="text-center">
                                      {{ cart.amount }}
                                    </td>
                                    <td class="text-center dir-ltr">
                                      {{ cart.dateForHumans }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="card-body">
                    <div class="form-group p-t-20">
                      <router-link
                        :to="{ name: ref }"
                        :disabled="btnLoading"
                        class="btn btn-default"
                        >{{ $t("app.back") }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import QuickView from "../layouts/QuickView.vue";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "Edit",
  components: {
    Header,
    QuickView,
    Footer,
  },
  mounted() {},
  data() {
    return {
      //
      auth: {
        access_token: "",
        role: "",
      },
      row: {
        // row
        name: "",
        email: "",
        mobile: "",
        image: "",
        notes: "",

        orders: [],
      },

      pgLoading: true,
      btnLoading: false,
      locale: "ar",
      ref: "donations",
    };
  },
  computed: {},
  created() {
    // access_token & Role
    if (localStorage.getItem("locale")) {
      this.locale = localStorage.getItem("locale");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }

    //
    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.ref + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.$axios(options)
        .then((res) => {
          this.pgLoading = false;

          // row
          this.row = res.data.row;
          // this.row.name   = (res.data.row.user)
          //                     ? res.data.row.user.first_name+' '+res.data.row.user.last_name : '';
          // this.row.email  = (res.data.row.user) ? res.data.row.user.email : '';
          // this.row.mobile = (res.data.row.user) ? res.data.row.user.mobile : '';
          this.row.name = res.data.row.name ? res.data.row.name : "";
          this.row.email = res.data.row.email_address
            ? res.data.row.email_address
            : "";
          this.row.mobile = res.data.row.mobile ? res.data.row.mobile : "";
          this.row.notes = res.data.row.notes ? res.data.row.notes : "";
          this.row.image = res.data.row.user ? res.data.row.user.image.url : "";

          this.row.orders = res.data.row.data;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 403) {
            this.removeLocalStorage();
            this.$router.push({ name: "forbidden" });
          } else {
            this.pgLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // remove LocalStorage
    removeLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("avatar");
      localStorage.removeItem("role");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>