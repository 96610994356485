import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    local: 'ar',
    locale: 'ar',
    fallbackLocale: 'ar',
    silentTranslationWarn: true,
    messages: {
        en: {
            auth: {
                email_address: 'Email address',
                password: 'Password',
                password_confirmation: 'Password Confirmation',
                remember_me: 'Remember me',
                forget_password: 'Forget Password ?',
                login: 'Login',
                return_login: 'return to login ?',
                submit: 'Submit',
                key_code: 'Key Code',
                new_password: 'New Password',
                con_new_password: 'Confirm New Password',
                keep_me_loggedin: 'Keep Me Logged In',
            },

            role: {
                view: 'View',
                add: 'Add',
                edit: 'Edit',
                delete: 'Delete',
            },

            seo: {
                name: 'SEO',
                title_ar: 'Title (Ar)',
                title_en: 'Title (En)',
                description_ar: 'Description (Ar)',
                description_en: 'Description (En)',
                keywords_ar: 'Keywords (Ar)',
                keywords_en: 'Keywords (En)',

            },

            app: {
                applications: 'Applications',
                hello: 'Hello',
                profile: 'Profile',
                logout: 'Logout',
                update: 'Update',
                create: 'Create',
                back: 'Back',
                cancel: 'Cancel',
                inbox: 'Inbox',

                project_link: 'Copy Link',
                orphan_approved: 'Approved',
                project_approved: 'Approved',
                preacher_approved: 'Approved',
                postgraduate_approved: 'Approved',
                relief_approved: 'Approved',
                endowment_approved: 'Approved',

                approve: 'Approve',
                unapprove: 'Unapprove',
                incase_unapprove: 'In case you select unapprove please write the reasons?',

                headerColor: 'Header Color',
                navColor: 'SideNav Color',
                languages: 'Languages',

                // msgs
                success_title: 'Great job,',
                success_msg: 'Request Completed Successfully.',
                warning_title: 'Wow, man',
                warning_msg: 'Slow down, ',
                error_title: 'Wow-wow,',
                error_msg: 'Something went wrong.',
                no_data: 'No data found.',
                invalid_credentials: 'Invalid Credentials',

                dashboard: 'Dashboard',
                add_new: 'Add New',
                bulk_actions: 'Bulk Actions',
                all: 'All',
                active: 'Active',
                completeProject: 'Completed Project',
                publicProject: 'Public Project',
                personalProject: 'Personal Project',
                notCompleteProject: 'New Project',
                share_links: 'Share Links',
                shared_link: 'Shared Link',
                visits: 'Total Visits',
                total_donations: 'Total Donations',
                inactive: 'Inactive',
                archive: 'Archive',
                trash: 'Trash',
                moveToTrash: 'Move to Trash',
                restore: 'Restore',
                deletePermanently: 'Delete Permanently',
                edit: 'Edit',

                english: 'English',
                arabic: 'Arabic',

                export: 'Export',
                excel: 'Excel',
                csv: 'CSV',
                print: 'Print',
            },

            nav: {
                navigation: 'NAVIGATION',
                dashboard: 'Dashboard',
                applications: 'Applications',
                categories: 'Categories',
                projects: 'Projects',
                orphans: 'Orphans',
                preachers: 'Preachers',
                reliefs: 'Releifs',
                endowments: 'Endowments',
                postgraduates: 'Postgraduates',
                urgent_help: 'Urgent help',
                stands: 'Stands',
                about: 'About',
                inbox: 'Inbox',
                offers: 'Offers',
                tags: 'Tags',
                accounts: 'Accounts',
                role: 'Roles',
                staff: 'Staff',
                reports: 'Reports',
                settings: 'App Settings',
                periodicReports: 'Periodic Reports',
            },


            dashboard: {
                total_visitors: 'Total Visitors',
                total_donations: 'Total Donations',
                total_inbox: 'Total Inbox',
                total_users: 'Total Users',

                today: 'Today',
                yesterday: 'Yesterday',
                last7days: 'Last 7 days',
                last28days: 'Last 28 days',
                last90days: 'Last 90 days',
                last180days: 'Last 180 days',
                lastCalendar: 'Last calendar year',

                visitor_during_year: 'Visitors During the year',
                weekly: 'Weekly',
                monthly: 'Monthly',
                quarter: 'Quarter',
                yearly: 'Yearly',

                where_visitors: 'Where your visitors located',
                recent_projects: 'Recent Projects',
                top_categories: 'Top Categories',
            },

            reports: {
                projects: 'Projects',
                projects_donations: 'Projects Donations',
                orphans: 'Orphans',
                orphans_donations: 'Orphans Donations',
                postgradutes: 'Postgradutes',
                postgradutes_donations: 'Postgradutes Donations',
                preachers: 'Preachers',
                preachers_donations: 'Preachers Donations',
                endowments: 'Endowments',
                endowments_donations: 'Endowments Donations',
                reliefs: 'Releifs',
                reliefs_donations: 'Releifs Donations',
                from: 'From',
                to: 'To',
            },
            projects: {
                hasDeductions: 'Has Deductions',
                mother_name: 'Mother Name',
                flag: 'Flag',
                pgName: 'Projects',
                analytics: 'Analytics',
                side: 'Type',
                news: 'News',
                allow_progress_bar: 'allow showing progress bar',
                notes: "notes",
                receipt: "receipt",

                eid: 'Eid',

                combination_donation: 'Combination Donations',
                total_amount: 'Total Amount',

                read: 'Read',
                msg_read: 'Read',
                msg_unread: 'Unread message',
                unread: 'Unread',
                read_msg: 'Read message',

                slug: 'Slug',
                title: 'Title',
                category: 'Category',
                city: 'City',
                target: 'Target',
                date: 'Date',
                actions: 'Actions',
                noData: 'No data found.',

                short_description: 'Short Description',
                description: 'Description',
                goals: 'Goals',
                achievements: 'Achievements',

                gallery: 'Gallery',
                name_en: 'Name [en]',
                name_ar: 'Name [ar]',
                video_url: 'Video URL',
                orUpload_image: 'Or Upload Image',

                cancel: 'Cancel',
                files: 'Files',
                upload_pdf: 'Upload PDF',
                videos: 'Videos',

                image: 'Image',
                remove: 'Remove',

                select_category: 'Select Category',
                select_city: 'Select City',

                donation_option1: 'Donation Option 1',
                donation_option2: 'Donation Option 2',
                donation_option3: 'Donation Option 3',
                donation_option4: 'Donation Option 4',

                allow_custom: 'Allow custom donation',
                allow_other_donate: 'Allow Donate other amount',
                allow_donate_all: 'All Doante all amount',
                auto_complete: 'Auto Complete on goal',
                project_completed: 'Project Completed',
                sort: 'Sort',
                google_maps: 'Google Maps',
                payment_links: 'Payments Links',
                payment_link: 'Payment Link',
                copyToClipboard: 'Copy to clipboard',
                payment_status: 'Payment Status',
                status: 'Status',
                active: 'Active',
                inactive: 'Inactive',
                preview_file: 'Preview File',
                information: 'Information',

                clientName: 'Client Name',
                clientSearch: 'search by client name',
                contributor: 'Contributor',
                ccode: 'Country Code',
                currency: 'Currency',
                block: 'Block',
                street: 'Street',
                buildno: 'Build No.',
                address: 'Address',

                // team
                pgTeams: 'Board of Directors',
                position: 'Position',

                // orphans
                pgOrphans: 'Orphans',
                name: 'Name',
                parents_condition: 'Parents Condition',
                birth_place: 'Birth Place',
                education: 'Education',
                orphanage: 'Orphanage',
                country: 'Country',
                select_country: 'Select Country',
                id_number: 'ID Number',
                gender: 'Gender',
                male: 'Male',
                female: 'Female',
                birth_date: 'Birth Date',

                // preachers
                pgPreachers: 'Preachers',

                edit: 'Edit',

                english: 'English',
                arabic: 'Arabic',
                add_new: 'Add New',
                update: ' Update ',
                create: 'Create',
                dashboard: 'Dashboard',

                marital_status: 'Marital Status',
                qualifications: 'Qualifcations ( The Quran memorized )',
                job: 'Job',
                job_place: 'Job Place',

                // Reliefs
                pgReliefs: 'Reliefs',
                author: 'Author',

                // endowments
                pgEndowments: 'Endowments',

                // about
                pgAbout: 'About',
                pgSMS: 'SMS',

                smsUsers: 'Users',

                smsAllUsers: 'All Users',
                smsMsg: 'SMS Text',
                send: 'Send',
                from_name: 'From Name',
                donation_voucher_value: 'Donation Voucher Value',
                donation_vouchers: 'Donation Vouchers',

                sms_placeholder: 'Search or add new',
                sms_addNew: 'add this new number',

                testimonials: 'Testimonials',
                hesaid: 'Desc',


                pgAccount: 'Accounts',
                pgAccounts: 'Accounts',
                role: 'Role',
                roles: 'Roles',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email Address',
                mobile: 'Mobile',
                mobile2: 'Hot Line',
                password: 'Password',
                member: 'Member',

                // categories
                pgCategories: 'Categories',
                projectsNo: 'Projects No.',
                cities_no: 'Cities No.',

                pgCities: 'Cities',

                pgCountries: 'Countries',
                pgContributors: 'Contributors',
                pgVolunteers: 'Volunteers',
                volunteersRequests: 'volunteers Requests',
                choose_contributors: 'Choose Contributors',
                choose_volunteers: 'Choose Volunteers',
                citiesNo: 'Cities No.',

                pgDonations: 'Donations',
                invoice_id: 'Invoice id#',
                donationTypes: 'Donation Types',
                donationType: 'Donation Type',
                donationTarget: 'Donation Target',
                donationValues: 'Donation Values',

                projectNomination: 'Project Nominations',
                nominationValues: 'Nomination Values',

                projectNominationOrder: 'Project Nomination Orders',

                amount: 'Amount',
                inbox: 'Inbox',
                subject: 'Subject',
                message: 'Message',
                reply: 'Reply',
                show: 'Show',
                multimedia: 'Multimedia',
                thumbnail: 'The importance of donating',

                pages: 'Pages',
                reports: 'Reports',

                usersNo: 'Users No.',
                permissions: 'Permissions',

                services: 'Services',
                settings: 'Settings',

                link: 'Link',


                our_mission: 'Our Mission',
                our_vision: 'Our Vision',
                our_goal: 'Our Goal',
                reward_orphan: 'Reward for sponsoring orphan',
                ways_orphan: 'Ways to sponsor orphan',
                type: 'Type',
                general_donation: 'General donation',
                all_donations: 'All Donations',
                about_orphans: 'About orphans',
                contact_us: 'Contact us',
                contacts_us: 'Contacts Info',
                urgent: 'Urgent',
                login: 'Login',
                register: 'Register',
                forget_password: 'Forget Password',
                reset_password: 'Reset Password',
                profile: 'Profile',
                shopping_cart: 'Shopping Cart',
                about_us: 'About us',
                our_services: 'Our Services',
                general_breif: 'General Breif',

                sliders: 'Slider',
                subtitle: 'SubTitle',
                button: 'Button',
                call_to_action: 'Call To Action',

                socials: 'Socials',
                provider: 'Provider',
                provider_url: 'Provider URL',

                staff: 'Staff',
                page_detail: 'Page Detail',

                cat_name: 'Category Name',
                pro_name: 'Project Name',
                pro_desc: 'Project Description',
                orp_name: 'Orphan Name',
                pre_name: 'Preacher Name',

                cat_image: 'Category Image',
                pro_image: 'Project Image',
                orp_image: 'Orphan Image',
                pre_image: 'Preahcer Image',

                cat_image_up: 'Upload Category Image',
                pro_image_up: 'Upload Project Image',
                orp_image_up: 'Upload Orphan Image',
                pre_image_up: 'Upload Preahcer Image',


                pro_info: 'Project Information',
                orp_info: 'Orphan Information',
                pre_info: 'Preacher Information',

                sms_lang: 'SMS Language',
                sms_content: 'SMS Content',

                pgPostgraduates: 'Postgraduates',
                donateNow: 'Donate Now',
                pgForeignOffices: 'Foregin Offices',
                amountHasGot: 'Amount',
                empName: 'Employee Name',

                orp_name_search: 'Search orphan name',
                pre_name_search: 'Search Preacher name',
                pro_name_search: 'Search Project Name',


                periodicReports: 'Periodic Reports',
                peri_report_name: 'Report Name',
                peri_department: 'Department',
                peri_elementName: 'Name',
                peri_status: 'Report Status',
                peri_approved: 'Approved',
                peri_underapprove: 'Pending',
                peri_reject: 'Rejection',
                peri_rejected: 'Rejected',

                data: 'Data',
                report_data: 'Report Data',
                donors_data: 'Donors Data',


                choose_cat: 'Select Category',
                choose_element: 'Select Name',

                report_txt1: 'Report Title',
                report_txt1_2: 'Report Name',
                report_txt2: 'Education Status',
                report_txt2_2: 'Phase',
                report_txt3: 'Health Status',
                report_txt4: 'Needs',
                report_txt5: 'Preservers from the Quran',
                report_txt6: 'Address',
                report_txt7: 'Notes',
                report_txt8: 'Advocacy',
                report_txt8_2: 'Collage',
                report_txt9: 'Last Report',

                isMosmy: 'without Donors',
                theNumberId: 'Id Number',
                whoSentiT: 'Sent',
                mr2yat: 'Images',
                upload_image: 'Upload image',

                cashDonations: 'Cash Donations',
                cash_amount: 'Cash Amount',

            },


        },









        /* arabic language */
        ar: {
            auth: {
                email_address: 'البريد الإلكتروني',
                password: 'كلمة المرور',
                password_confirmation: 'تأكيد الرقم السري',
                remember_me: 'تذكرني',
                forget_password: 'نسيت كلمة المرور ؟',
                login: 'تسجيل دخول',
                return_login: 'رجوع لتسجيل الدخول؟',
                submit: 'إرسالة',
                key_code: 'الكود',
                new_password: 'كلمة المرور الجديدة',
                con_new_password: 'تأكيد كلمة المرور',
                keep_me_loggedin: 'تذكرني',
            },

            role: {
                view: 'مشاهدة',
                add: 'إضافة',
                edit: 'تعديل',
                delete: 'حذف',
            },


            seo: {

                name: ' بيانات البحث',
                title_ar: 'عنوان الصفحة بالعربية',
                title_en: 'عنوان الصفحة بالإنجليزية',
                description_ar: 'وصف الصفحة بالعربية',
                description_en: 'وصف الصفحة بالإنجليزية',
                keywords_ar: 'كلمات البحث بالعربية',
                keywords_en: 'كلمات البحث بالإنجليزية',

            },

            app: {
                applications: 'التطبيقات',
                hello: 'مرحباً ',
                profile: 'بروفيل',
                logout: 'تسجيل خروج',
                update: ' تعديل ',
                create: 'إضافة',
                back: 'رجوع',
                cancel: 'إلغاء',
                inbox: 'صندوق البريد',

                project_link: 'نسخ الرابط ',

                orphan_approved: 'الموافقة علي اليتيم',
                project_approved: 'الموافقة علي المشروع',
                preacher_approved: 'الموافقة علي الدعاه',
                postgraduate_approved: 'الموافقة علي الدراسات العليا',
                relief_approved: 'الموافقة علي الإغاثات',
                endowment_approved: 'الموافقة علي الوقفيات',


                approve: 'الموافقة',
                unapprove: 'عدم الموافقة',
                incase_unapprove: 'في حالة عدم الموافقة من فضلك اذكر السبب ؟',

                headerColor: 'لون الهيدر',
                navColor: 'لون القائمة',
                languages: 'اللغة',

                // msgs
                success_title: 'عمل رائع,',
                success_msg: 'تم تنفيذ طلبك بنجاح',
                warning_title: 'أنتظر من فضلك',
                warning_msg: '',
                error_title: 'خطأ',
                error_msg: 'حدث خطأ من الخادم من فضلك حاول مرة آخري',
                no_data: 'لا يوجد بيانات',
                invalid_credentials: 'البيانات غير صحيحة',

                dashboard: 'لوحة التحكم',
                add_new: 'إضف جديد',
                bulk_actions: 'الأعدادات',
                all: 'الكل',
                active: 'نشط',
                completeProject: 'مشروع منجز',
                publicProject: 'مشروع عام',
                personalProject: 'مشروع شخصي',
                notCompleteProject: 'مشروع جديد',
                share_links: 'وصلات المشاركة',
                shared_link: 'رابط المشاركة',
                visits: 'عدد الزوار',
                total_donations: 'إجمالي التبرعات',
                inactive: 'غير نشط',
                archive: 'أرشيف',
                trash: 'السلة',
                moveToTrash: 'أنقل للسلة',
                restore: 'إسترجاع',
                deletePermanently: 'حذف نهائي',
                edit: 'تعديل',

                english: 'إنجليزي',
                arabic: 'عربي',

                export: 'إستخراج',
                excel: 'أكسيل شيت',
                csv: 'CSV',
                print: 'طباعة',
            },

            nav: {
                projects: 'المشاريع',
                reliefs: 'اغاثات عاجلة',
                orphans: 'الأيتام',
                preachers: 'الدعاه',
                postgraduates: 'الدراسات العليا',
                endowments: 'الوقفيات',
                periodicReports: 'التقارير الدورية',
            },

            dashboard: {
                total_visitors: 'إجمالي الزوار',
                total_donations: 'إجمالي عمليات التبرع',
                total_amounts: 'إجمالي مبالغ التبرع',
                total_inbox: 'إجمالي الرسائل',
                total_users: 'إجمالي الأعضاء',

                today: 'اليوم',
                yesterday: 'أمس',
                last7days: 'منذ ٧ ايام',
                last28days: 'منذ ٢٨ يوم',
                last90days: 'منذ ٩٠ يوم',
                last180days: 'منذ ١٨٠ يوم',
                lastCalendar: 'منذ السنة الحالية',

                visitor_during_year: 'إحصائيات الزوار خلال السنة',
                weekly: 'اسبوعي',
                monthly: 'شهري',
                quarter: 'ربع سنوي',
                yearly: 'سنوي',

                where_visitors: 'أماكن الزوار',
                recent_projects: 'أحدث المشاريع',
                top_categories: 'أحدث الأقسام',
            },

            reports: {
                projects: 'المشاريع',
                projects_donations: 'تبرعات المشاريع',
                postgradutes: 'الدراسات العليا',
                postgradutes_donations: 'تبرعات الدراسات اعليا',
                orphans: 'الأيتام',
                orphans_donations: 'تبرعات الأيتام',
                preachers: 'الدعاه',
                preachers_donations: 'تبرعات الدعاه',
                endowments: 'الوقفيات',
                endowments_donations: 'تبرعات الوقفيات',
                reliefs: 'الإغاثات',
                reliefs_donations: 'تبرعات الإغاثات',
                from: 'من',
                to: 'إلى',
            },

            projects: {
                hasDeductions: 'قابل للإستقطاع',
                mother_name: 'اسم الأم',
                flag: 'العلم',
                pgName: 'المشاريع',
                analytics: 'إحصائيات',
                side: 'الجهه',
                news: 'الأخبار',
                allow_progress_bar: 'السماح بظهور شريط احراز التقدم',
                notes: "ملاحظات",
                receipt: "الفاتورة",


                eid: 'عيدية اليتيم',
                combination_donation: 'تقارير مجمعة',
                total_amount: 'إجمالي التبرعات',

                read: 'رسائل مقروءة',
                msg_read: 'تم قراءة الرسالة',
                msg_unread: 'لم يتم قراءة الرسالة',
                unread: 'رسائل جديد',
                read_msg: 'قراءة الرسالة',

                slug: 'الرابط',
                title: 'العنوان',
                category: 'القسم',
                city: 'المدينة',
                target: 'مبلغ المشروع',
                date: 'التاريخ',
                actions: 'الإعدادات',
                noData: 'لا يوجد بيانات',

                short_description: 'وصف مختصر',
                description: 'الوصف',
                goals: 'الأهداف',
                achievements: 'مراحل إنجاز المشروع',

                gallery: 'معرض الصور والفيديوهات المتحركة',
                name_en: 'الأسم [en]',
                name_ar: 'الأسم [ar]',
                video_url: 'رابط الفيديو',
                orUpload_image: 'أو ارفع صورة',

                cancel: 'إلغاء',
                files: 'الملفات المرفقة',
                upload_pdf: 'أرفع الملف',
                videos: 'الفيديوهات',

                image: 'الصورة',
                remove: 'حذف',

                select_category: 'أختر القسم',
                select_city: 'أختر المدينة',

                donation_option1: 'مبلغ التبرع ١',
                donation_option2: 'مبلغ التبرع ٢',
                donation_option3: 'مبلغ التبرع ٣',
                donation_option4: 'مبلغ التبرع ٤',

                allow_custom: ' السماح بإختيار مبالغ التبرع ',
                allow_other_donate: 'السماح بكتابة مبلغ آخر',
                allow_donate_all: 'السماح بالتبرع بكامل المبلغ',

                auto_complete: ' أخفاء المشروع عند الأنتهاء ',
                project_completed: ' تم الإنتهاء من المشروع ',
                sort: 'الترتيب',
                google_maps: 'رابط خريطة جوجل',
                payment_links: 'روابط الدفع',
                payment_link: 'رابط الدفع',
                copyToClipboard: 'نسخ الرابط',
                payment_status: 'حالة الدفع',
                status: 'الحالة',
                active: 'نشط',
                inactive: 'غير نشط',
                preview_file: 'عرض الملف',
                information: 'البيانات',


                clientName: 'إسم العميل',
                clientSearch: 'بحث باسم العميل',
                contributor: 'المساهم',
                ccode: 'كود الدولة',
                currency: 'العملة',
                block: 'المنطقة',
                street: 'الشارع',
                buildno: 'رقم المنبي',
                address: 'العنوان',


                // team
                pgTeams: 'مجلس الإدارة',
                position: 'الوظيفة',

                // orphans
                pgOrphans: 'الأيتام',
                name: 'الأسم',
                parents_condition: 'حالة الوالدين',
                birth_place: 'مكان الولادة',
                education: 'التعليم',
                orphanage: 'دار الأيتام',
                country: 'الدولة',
                select_country: 'أختر الدولة',
                id_number: 'رقم الهوية',
                gender: 'الجنس',
                male: 'ذكر',
                female: 'أنثي',
                birth_date: 'تاريخ الميلاد',

                // preachers
                pgPreachers: 'الدعاه',

                edit: 'تعديل',

                english: 'انجليزي',
                arabic: 'عربي',
                add_new: 'أضف جديد',
                update: ' تعديل ',
                create: 'حفظ',
                dashboard: 'لوحة التحكم',

                marital_status: 'الحالة الإجتماعية',
                qualifications: 'المؤهلات ( عدد مرات حفظ القرآن الكريم )',
                job: 'الوظيفة',
                job_place: 'مكان الوظيفة',

                // Reliefs
                pgReliefs: 'الإغاثات',
                author: 'المؤلف',

                // endowments
                pgEndowments: 'الوقفيات',

                // about
                pgAbout: 'من نحن',
                pgSMS: 'الرسائل النصية',

                smsUsers: 'أختر الأعضاء',
                smsAllUsers: 'كل الأعضاء',
                smsMsg: 'نص الرسالة',
                send: 'إرسال',
                from_name: 'اسم الراسل',
                donation_voucher_value: 'قيمة قسيمة الهدية',
                donation_vouchers: 'قسايم التبرعات',

                sms_placeholder: 'ابحث او اضف رقم جديد',
                sms_addNew: 'ابحث او أضف رقم جديد',

                testimonials: 'قالو عنا',
                hesaid: 'قال عنا',

                pgAccount: 'الأعضاء',
                pgAccounts: 'الأعضاء',
                role: 'الصلاحية',
                roles: 'الصلاحيات',
                first_name: 'الأسم الأول',
                last_name: 'الأسم الأخير',
                email: 'البريد الإلكتروني',
                mobile: 'رقم الجوال',
                mobile2: 'الخط الساخن',
                phone: 'هاتف الجمعية',
                password: 'الرقم السري',
                member: 'عضو',

                // categories
                pgCategories: 'الأقسام',
                projectsNo: 'عدد المشاريع',
                cities_no: 'عدد المدن',

                pgCities: 'المدن',

                pgCountries: 'الدول',
                pgContributors: 'المساهمين',
                pgVolunteers: 'المتبرعين',
                volunteersRequests: 'طلبات التطوع',
                choose_contributors: 'أختر المساهمين',
                choose_volunteers: 'أختر المتبرع',
                citiesNo: 'عدد المدن',

                pgDonations: 'التبرعات',
                invoice_id: 'رقم الفاتورة',
                donationTypes: 'أنواع التبرعات',
                donationType: 'نوع التبرع',
                donationValues: 'قيمة التبرعات',

                donationTarget: 'مبلغ التبرع',

                projectNomination: 'صمم مشروعك',
                nominationValues: 'قيمة المشروع',

                projectNominationOrder: 'تبرعات صمم مشروعك',

                amount: 'المبلغ',
                inbox: 'صندوق البريد',
                subject: 'موضوع الرسالة',
                message: 'الرسالة',
                reply: 'الرد',
                show: 'أظهر',
                multimedia: 'الوسائط المتعددة',
                thumbnail: 'أهمية التبرع',

                pages: 'الصفحات',
                reports: 'التقارير',

                usersNo: 'عدد المستخدمين',
                permissions: 'الصلاحيات',

                services: 'الخدمات',
                settings: 'الإعدادات',

                link: 'الرابط الخارجي',


                our_mission: 'مهمتنا',
                our_vision: 'رؤيتنا',
                our_goal: 'هدفنا',
                reward_orphan: 'ثواب كفالة يتيم',
                ways_orphan: 'طرق كفالة الأيتام',
                type: 'النوع',
                general_donation: 'التبرع العام',
                all_donations: 'كل التبرعات',
                about_orphans: 'عن الأيتام',
                contact_us: 'تواصل معنا',
                contacts_us: 'بيانات الإتصال',
                urgent: 'عاجل',
                login: 'تسجيل دخول',
                register: 'تسجيل حساب جديد',
                forget_password: 'نسيت الرقم السري',
                reset_password: 'إستعادة الرقم السري',
                profile: 'صفحتي الشخصية',
                shopping_cart: 'سلة التبرعات',
                about_us: 'من نحن',
                our_services: 'خدماتنا',
                general_breif: 'نبذة عامة',

                sliders: 'معرض الصور',
                subtitle: 'عنوان فرعي',
                button: 'الزر',
                call_to_action: 'اذهب ألي عن الضغط',

                socials: 'التواصل الإجتماعي',
                provider: 'النوع',
                provider_url: 'الرابط',

                staff: 'الموظفين',
                page_detail: 'تفاصيل الصفحة',

                cat_name: 'اسم القسم',
                pro_name: 'اسم المشروع',
                pro_desc: 'وصف المشروع',
                orp_name: 'اسم اليتيم',
                pre_name: 'اسم الداعيه',

                cat_image: 'صورة القسم',
                pro_image: 'صورة المشروع',
                orp_image: 'صورة اليتيم',
                pre_image: 'صورة الداعيه',

                cat_image_up: 'رفع صورة القسم',
                pro_image_up: 'رفع صورة المشروع',




                orp_image_up: 'رفع صورة اليتيم',
                pre_image_up: 'رفع صورة الداعيه',

                pro_info: 'معلومات المشروع',
                orp_info: 'معلومات عن اليتيم',
                pre_info: 'معلومات عن الداعيه',

                sms_lang: 'لغة الرسالة',
                sms_content: 'محتوي الرسالة',


                pgPostgraduates: 'الدراسات العليا',
                donateNow: 'تبرع الآن',
                pgForeignOffices: 'المكاتب الخارجية',
                amountHasGot: 'قيمة التبرع',
                empName: 'اسم الموظف',

                orp_name_search: 'بحث عن اسم اليتيم',
                pre_name_search: 'بحث عن اسم الداعيه',
                pro_name_search: 'بحث عن اسم المشروع',

                periodicReports: 'التقارير الدورية',
                peri_report_name: 'اسم التقرير',
                peri_department: 'القسم',
                peri_elementName: 'اسم العنصر',
                peri_status: 'حاله التقرير',
                peri_approved: 'تم الموافقة',
                peri_underapprove: 'تم الرفض',
                peri_reject: 'المرفوض',
                peri_rejected: 'تم الرفض',

                data: 'البيانات',
                report_data: 'تفاصيل التقارير',
                donors_data: 'بيانات المتبرع',

                choose_cat: 'اختر القسم',
                choose_element: 'أختر العنصر',

                report_txt1: 'عنوان التقرير',
                report_txt1_2: 'عنوان التحديث',
                report_txt2: 'الحالة الدراسية',
                report_txt2_2: 'المرحلة الدراسية',
                report_txt3: 'الحالة الصحية',
                report_txt4: 'متطلبات واحتياجات',
                report_txt5: 'يحفظ من القرآن',
                report_txt6: 'مكان السكن',
                report_txt7: 'ملاحظات المشرف',
                report_txt8: 'النشاط الدعوي',
                report_txt8_2: 'الجامعة',
                report_txt9: 'اخر التقارير',

                isMosmy: 'تقارير موسمي',
                theNumberId: 'الرقم التعريفي',
                whoSentiT: 'المرسل',
                mr2yat: 'المرئيات',
                upload_image: 'ارفع الصورة',

                cashDonations: 'التبرعات النقدية',
                cash_amount: 'مبلغ التبرع',
            },

        },
    }
})