<template>
  <div class="">
    <Header v-on:headerToChild="onSearchSubmit"></Header>

    <div class="page-container">
      <QuickView></QuickView>

      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <!-- Header -->
          <div class="page-header">
            <h2
              class="header-title text-capitalize"
              :class="locale == 'ar' ? 'dir-rtl go-right' : ''"
            >
              <i class="mdi mdi-cart"></i>
              {{ $t("projects.pgDonations") }}
            </h2>
            <router-link
              :to="{ name: refs }"
              class="btn btn-primary btn-sm btn-rounded btn-bold btn-opacity"
              >Back
            </router-link>

            <!-- Role -->
            <label
              class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right"
            >
              {{ auth.role }}
            </label>
            <!-- End Role -->

            <div class="clearfix"></div>

            <div class="pull-right hidden-sm">
              <div class="dropdown">
                <!-- Bulk Action -->
                <!-- <div class="dropdown">
                        <button type="button" 
                            data-toggle="dropdown" 
                            class="btn btn-danger btn-sm dropdown-toggle btn-bold btn-size-sm"
                            :disabled="bulkLoading">
                          <span v-if="!bulkLoading">{{ $t('app.bulk_actions') }}</span>
                          <span v-if="bulkLoading">
                            <div class="loader loader-sm"></div>
                          </span>
                        </button> 
                        <div class="dropdown-menu">
                          
                          <a v-if="status != 'trash'"
                              @click="multiMoveToTrash()"
                              class="dropdown-item" 
                              href="javascript:;">{{ $t('app.moveToTrash') }}
                          </a>
                          <a v-if="status == 'trash'"
                              @click="multiRestoreFromTrash()"
                              class="dropdown-item" 
                              href="javascript:;">{{ $t('app.restore') }}
                          </a>
                          <a v-if="status == 'trash'"
                              @click="multiDeletePermanently()"
                              class="dropdown-item" 
                              href="javascript:;">{{ $t('app.deletePermanently') }}
                          </a>
                        </div>
                      </div>  -->
                <!-- End Bulk Action -->

                <!-- Export -->
                <div class="dropdown">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm dropdown-toggle btn-size-sm"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :disabled="exportLoading"
                  >
                    <span v-if="!exportLoading">{{ $t("app.export") }}</span>
                    <span v-if="exportLoading">
                      <div class="loader loader-sm"></div>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <download-excel
                      class="dropdown-item cursor-pointer"
                      :fetch="fetchExport"
                      :fields="exp.json_fields"
                      :before-generate="startDownload"
                      :before-finish="finishDownload"
                      :worksheet="refs"
                      :name="refs + '.xls'"
                      >{{ $t("app.excel") }}
                    </download-excel>
                    <download-excel
                      class="dropdown-item cursor-pointer"
                      :fetch="fetchExport"
                      :fields="exp.json_fields"
                      :before-generate="startDownload"
                      :before-finish="finishDownload"
                      type="csv"
                      :worksheet="refs"
                      :name="refs + '.xls'"
                      >{{ $t("app.csv") }}
                    </download-excel>
                    <a
                      class="dropdown-item"
                      href="javascript:;"
                      v-print="'#printMe'"
                      >{{ $t("app.print") }}
                    </a>
                  </div>
                </div>
                <!-- End Export -->
              </div>
            </div>

            <!-- Breadcrumb -->
            <nav
              class="breadcrumb breadcrumb-dash"
              :class="locale == 'ar' ? 'dir-rtl' : ''"
            >
              <router-link :to="{ name: 'dashboard' }" class="breadcrumb-item">
                <i class="ti-home p-r-5"></i>
                {{ $t("projects.dashboard") }}
              </router-link>
              <span class="breadcrumb-item text-capitalize active">{{
                $t("projects.pgDonations")
              }}</span>
            </nav>
            <!-- End Breadcrumb -->
          </div>
          <!-- End Header -->

          <div class="clearfix"></div>
          <div class="col-12 row" :class="locale == 'ar' ? 'dir-rtl' : ''">
            <div class="col-md-1 col-sm-12"></div>
            <div class="col-md-3 col-sm-6">
              <div class="form-group">
                <label>{{ $t("reports.from") }}</label>
                <input type="date" class="form-control" v-model="row.from" />
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="form-group">
                <label>{{ $t("reports.to") }}</label>
                <input type="date" class="form-control" v-model="row.to" />
              </div>
            </div>
            <div class="col-md-2 col-sm-6">
              <div class="form-group">
                <label>{{ $t("projects.donationType") }}</label>
                <select class="form-control" v-model="row.model">
                  <option value="general_donations">
                    {{ $t("projects.general_donation") }}
                  </option>
                  <option value="projects">{{ $t("nav.projects") }}</option>
                  <!-- <option value="orphans">{{ $t('nav.orphans') }}</option> -->
                  <!-- <option value="endowments">{{ $t('nav.endowments') }}</option> -->
                </select>
              </div>
            </div>
            <div class="col-md-1 col-sm-6">
              <div class="form-group" style="width: 100%">
                <label class="btn-opacity">Button</label>
                <button
                  type="button"
                  @click="fetchData()"
                  class="btn btn-danger"
                  style="height: 44px"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="col-md-2 col-sm-12"></div>
          </div>

          <div class="card">
            <!-- Status  -->
            <div class="card-header">
              <h5 class="card-title text-muted f14">
                <router-link
                  :to="{ name: refs }"
                  :class="status == '' ? 'active' : ''"
                  >{{ $t("app.all") }}</router-link
                >
                <span style="font-family: airla"> ({{ statusBar.all }}) </span>
                <!--  <span class="obstacle"> | </span> -->

                <!-- <router-link :to="{ name: 'status-'+refs, params:{ status:'active'} }"
                        :class="(status == 'active') ? 'active' : '' ">Active</router-link> 
                      <span> ({{statusBar.active}}) </span> <span class="obstacle"> | </span>

                      <router-link :to="{ name: 'status-'+refs, params:{ status:'inactive'} }"
                        :class="(status == 'inactive') ? 'active' : '' ">Inactive</router-link> 
                      <span> ({{statusBar.inactive}}) </span> <span class="obstacle"> | </span> -->

                <!--  <router-link :to="{ name: 'status-'+refs, params:{ status:'trash'} }"
                        :class="(status == 'trash') ? 'active' : '' ">{{ $t('app.trash') }}</router-link> 
                      <span style="font-family: airla"> ({{statusBar.trash}}) </span> -->
              </h5>

              <div class="col-md-12 text-center">
                <h4 class="">
                  {{ $t("app.total_donations") }} <br />
                  <span v-if="!dataLoading"> {{ totalDonation }}</span>
                </h4>
              </div>
              <div class="col-md-12"></div>
              <div
                class="col-md-10 row"
                style="margin-left: 0; padding: 0; direction: rtl; float: right"
              >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="'بحث'"
                    v-model="search"
                    v-on:keyup.enter="fetchData('', true)"
                  />
                </div>
                <div class="col-md-6"></div>
              </div>

              <!-- Show Entries -->
              <div class="dropdown pull-right">
                <div class="display-inline">
                  <button
                    type="button"
                    class="btn btn-light btn-sm dropdown-toggle"
                    id="dropdownMenuButton4"
                    data-toggle="dropdown"
                  >
                    <span> {{ $t("projects.show") }} </span>
                    <span v-if="!showLoading"> {{ show }}</span>
                    <span v-if="showLoading">
                      <div class="loader loader-sm"></div>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu ui-min-w120"
                    aria-labelledby="dropdownMenuButton4"
                  >
                    <a
                      class="dropdown-item cursor-pointer"
                      :class="show == 10 ? 'active' : ''"
                      @click="onShow(10)"
                      >10
                    </a>
                    <a
                      class="dropdown-item cursor-pointer"
                      :class="show == 25 ? 'active' : ''"
                      @click="onShow(25)"
                      >25
                    </a>
                    <a
                      class="dropdown-item cursor-pointer"
                      :class="show == 50 ? 'active' : ''"
                      @click="onShow(50)"
                      >50
                    </a>
                    <a
                      class="dropdown-item cursor-pointer"
                      :class="show == 100 ? 'active' : ''"
                      @click="onShow(100)"
                      >100
                    </a>
                  </div>
                </div>
              </div>
              <!-- End Show Entries -->
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table
                  id="printMe"
                  class="table"
                  :class="locale == 'ar' ? 'dir-rtl' : ''"
                >
                  <thead>
                    <tr class="top-border-none">
                      <th scope="col" style="width: 5%">
                        <div class="checkbox">
                          <input
                            id="selectbl0"
                            class="custom-control-input"
                            type="checkbox"
                            v-model="selectAll"
                            @click="select"
                          />
                          <label for="selectbl0"></label>
                        </div>
                      </th>
                      <th scope="col" style="width: 15%">
                        {{ $t("projects.invoice_id") }}
                        <span
                          v-if="!orderLoading"
                          @click="onOrderBy('title')"
                          class="cursor-pointer ui-ml5"
                          :class="
                            order == 'DESC'
                              ? 'ti-arrow-down'
                              : order == 'ASC'
                              ? 'ti-arrow-up'
                              : 'ti-exchange-vertical'
                          "
                        >
                        </span>
                        <span v-if="orderLoading" class="ui-ml5">
                          <div class="loader loader-sm"></div>
                        </span>
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.clientName") }}
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.mobile") }}
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.notes") }}
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.side") }}
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.amount") }}
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.date") }}
                      </th>
                      <th scope="col" class="text-center" style="width: 15%">
                        {{ $t("projects.actions") }}
                      </th>
                    </tr>
                  </thead>

                  <!-- Loader -->
                  <tr v-if="dataLoading">
                    <td colspan="9" class="text-center ui-h100">
                      <div class="loader loader-lg ui-mtop-15"></div>
                    </td>
                  </tr>

                  <!-- No data found -->
                  <tr v-if="!dataLoading && !rows.length">
                    <td colspan="9" class="text-center ui-h100">
                      <span>{{ $t("projects.noData") }}</span>
                    </td>
                  </tr>

                  <!-- Rows -->
                  <tbody v-if="!dataLoading && rows.length">
                    <tr
                      v-for="(row, index) in rows"
                      :key="index"
                      style="font-weight: 700"
                    >
                      <td>
                        <div class="checkbox">
                          <input
                            :id="'selectbl' + row.id"
                            class="custom-control-input"
                            type="checkbox"
                            v-model="selected"
                            :value="row.id"
                          />
                          <label :for="'selectbl' + row.id"></label>
                        </div>
                      </td>

                      <!-- Name -->
                      <td class="font-weight-semi-bold">
                        <router-link
                          v-if="permissions.edit"
                          :to="{
                            name: 'show-donation-receipt',
                            params: { id: row.encrypt_id },
                          }"
                          target="_blank"
                          class="default-color text-decoration-hover"
                        >
                          {{ row.invoice_id }}
                        </router-link>
                        <span v-if="!permissions.edit" class="fweight-600">{{
                          row.invoice_id
                        }}</span>
                      </td>
                      <!-- End Name -->

                      <!-- Email -->
                      <td class="text-center">
                        <span v-if="row.user">
                          {{ row.user.first_name }} {{ row.user.last_name }}
                        </span>
                        <span v-if="!row.user && row.name">
                          {{ row.name }}
                        </span>
                        <span v-else> --- </span>
                      </td>
                      <!-- End Email -->

                      <!-- Email -->
                      <td class="text-center">
                        <span v-if="row.mobile">
                          {{ row.mobile }}
                        </span>
                      </td>
                      <!-- End Email -->
                      <!-- Email -->
                      <td class="text-center">
                        <span v-if="row.notes">
                          {{ row.notes }}
                        </span>
                        <span v-else> --- </span>
                      </td>
                      <!-- End Email -->

                      <!-- Name -->
                      <td class="text-center">
                        <template v-if="row.data">
                          <label v-if="row.data[0].image">
                            <img
                              :src="row.data[0].image"
                              style="width: 70px; height: 50px"
                            />
                          </label>
                          <p></p>
                          <label
                            v-if="row.data[0].name"
                            style="margin-right: 10px; margin-left: 10px"
                          >
                            {{ row.data[0].name }}
                          </label>
                        </template>
                      </td>
                      <!-- End Name -->

                      <!-- Amount -->
                      <td class="text-center">
                        {{ row.amount }}
                      </td>
                      <!-- End Amount -->

                      <!-- Date -->
                      <td class="text-center ui-date dir-ltr">
                        {{ row.date }}
                      </td>
                      <!-- End Date -->

                      <!-- Action -->
                      <td class="text-center">
                        <div class="dropdown">
                          <a
                            href="javascript:;"
                            data-toggle="dropdown"
                            class="text-black f20"
                          >
                            <span v-if="!row.loading" class="ti-more"></span>
                            <div
                              v-if="row.loading"
                              class="loader loader-xs"
                              style="margin-top: -15px; margin-left: -12px"
                            ></div>
                          </a>
                          <div class="dropdown-menu ui-min-w120">
                            <!-- <router-link v-if="!row.trash"
                                    :to="{ name: 'show-'+refs, params:{id:row.encrypt_id} }" 
                                        class="dropdown-item">{{ $t('projects.show') }}
                                  </router-link> -->
                            <router-link
                              v-if="!row.trash"
                              :to="{
                                name: 'show-donation-receipt',
                                params: { id: row.encrypt_id },
                              }"
                              target="_blank"
                              class="dropdown-item"
                              >{{ $t("projects.receipt") }}
                            </router-link>
                            <!--  <a v-if="!row.trash" 
                                      @click="row.loading = true; moveToTrash(row.id)"
                                      class="dropdown-item" href="javascript:;">{{ $t('app.moveToTrash') }}
                                  </a>
                                  <a v-if="row.trash" 
                                      @click="row.loading = true; restoreFromTrash(row.id)"
                                      class="dropdown-item" href="javascript:;">{{ $t('app.restore') }}
                                  </a>
                                  <a v-if="row.trash" 
                                      @click="row.loading = true; deletePermanently(row.id)"
                                      class="dropdown-item" href="javascript:;">{{ $t('app.deletePermanently') }}
                                  </a> -->
                          </div>
                        </div>
                      </td>
                      <!-- End Action -->
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="top-border-none">
                      <th scope="col">
                        <div class="checkbox">
                          <input
                            id="selectbl0"
                            class="custom-control-input"
                            type="checkbox"
                            v-model="selectAll"
                            @click="select"
                          />
                          <label for="selectbl0"></label>
                        </div>
                      </th>
                      <th scope="col">{{ $t("projects.invoice_id") }}</th>
                      <th scope="col" class="text-center">
                        {{ $t("projects.name") }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ $t("projects.amount") }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ $t("projects.date") }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ $t("projects.actions") }}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <!-- <nav  v-if="rows.length !== 0" aria-label="Page navigation example ui-mt20">
                        <ul class="pagination">
                          <li v-bind:class="[{disabled: !pagination.prev_page_url}]" class="page-item">
                            <a class="btn btn-default" href="javascript:" 
                                @click="fetchData(pagination.prev_page_url)">Previous</a>
                          </li>
                          <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item">
                            <a class="btn btn-default" href="javascript:" 
                                @click="fetchData(pagination.next_page_url)">Next</a>
                          </li>
                        </ul>
                        <p :class="(locale == 'ar') ? '' : 'pull-right'" class=" ui-mt-30 f13">
                          <i>Page {{ pagination.current_page }} or {{ pagination.last_page }}</i>
                        </p>
                      </nav> -->

              <paginate
                v-if="!dataLoading && rows.length !== 0"
                :page-count="paginateCount"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :click-handler="fetchData"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import QuickView from "../layouts/QuickView.vue";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "List",
  components: {
    Header,
    QuickView,
    Footer,
  },
  data() {
    return {
      // export
      exp: {
        json_fields: {
          id: "id",
          invoice_id: "invoice_id",
          clientName: "name",
          mobile: "mobile",
          email: "email",
          notes: "notes",
          amount: "amount",
          date: "date",
        },
        json_data: [],
        json_meta: [
          [
            {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
      },

      // auth
      auth: {
        access_token: "",
        role: "",
      },

      // permissions
      permissions: {
        add: false,
        edit: false,
        delete: false,
      },

      // statusBar
      statusBar: {
        all: 0,
        active: 0,
        inactive: 0,
        trash: 0,
      },

      row: {
        from: "",
        to: "",
        model: "",
      },

      totalDonation: "",

      //
      locale: "ar",
      search: "",
      status: "",
      filter_by: "",
      filter: "",
      order: "",
      order_by: "",

      selected: [],
      selectAll: false,
      plural: "",

      dataLoading: true,
      bulkLoading: true,
      exportLoading: true,
      authorLoading: false,
      showLoading: false,
      orderLoading: false,
      rows: [],
      show: 10,
      pagination: {},
      paginateCount: 10,

      //
      refs: "donations",
    };
  },
  mounted() {},
  watch: {
    $route() {
      // Status By
      if (this.$route.params.status) {
        this.status = this.$route.params.status;
      } else {
        this.status = "";
      }

      // Filters by
      if (this.$route.params.filter_by) {
        this.filter_by = this.$route.params.filter_by;
      }
      if (this.$route.params.filter) {
        this.filter = this.$route.params.filter;
      }

      // fetch Data
      //this.fetchData('', true);
    },
  },
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("locale")) {
      this.locale = localStorage.getItem("locale");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    // Status By
    if (this.$route.params.status) {
      this.status = this.$route.params.status;
    }

    // Filters by
    if (this.$route.params.filter_by) {
      this.filter_by = this.$route.params.filter_by;
    }
    if (this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

    // fetch Data
    this.fetchData("", true);
  },
  methods: {
    // fetchData
    fetchData(page, loading = false) {
      if (loading) {
        this.dataLoading = true;
      }
      (this.plural = ""), (this.selectAll = false);
      this.selected = [];
      console.log("page", page);

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs,
        method: "GET",
        data: {},
        params: {
          page: page,
          from: this.row.from,
          to: this.row.to,
          model: this.row.model,
          locale: this.locale,
          status: this.status,
          filter_by: this.filter_by,
          filter: this.filter,
          search: this.search,
          paginate: this.show,
          order: this.order,
          order_by: this.order_by,
        },
      };
      let vm = this;
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.bulkLoading = false;
          this.exportLoading = false;
          this.showLoading = false;
          this.orderLoading = false;
          this.authorLoading = false;

          // Permissions
          this.permissions.add = res.data.permissions.add;
          this.permissions.edit = res.data.permissions.edit;
          this.permissions.delete = res.data.permissions.delete;

          // StatusBar
          //this.statusBar.all = res.data.statusBar.all;
          //this.statusBar.active = res.data.statusBar.active;
          //this.statusBar.inactive = res.data.statusBar.inactive;
          //this.statusBar.trash = res.data.statusBar.trash;

          // rows & paginate
          this.rows = res.data.rows;
          this.totalDonation = res.data.total;
          this.row.from = res.data.from;
          this.row.to = res.data.to;
          // this.row.model = res.data.model_type;
          if (res.data.paginate.total) {
            this.statusBar.all = res.data.paginate.total;
            vm.makePagination(res.data.paginate);
            this.paginateCount = Math.ceil(res.data.paginate.total / 10);
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 403) {
            this.removeLocalStorage();
            this.$router.push({ name: "forbidden" });
          } else {
            this.dataLoading = false;
            iziToast.warning({
              icon: "ti-ban",
              title: "Wow, man",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // Pagination
    makePagination(meta) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: meta.next_page_url,
        prev_page_url: meta.prev_page_url,
      };
      this.pagination = pagination;
    },

    // Search Submit
    onSearchSubmit(value) {
      this.search = value;
      this.fetchData("", true);
    },

    // Show
    onShow(show) {
      this.showLoading = true;
      this.show = show;
      this.fetchData("", true);
    },

    // Order By
    onOrderBy(column) {
      this.orderLoading = true;
      this.order_by = column;
      if (this.order == "ASC") {
        this.order = "DESC";
      } else {
        this.order = "ASC";
      }

      this.fetchData("", true);
    },

    // remove Filter
    removeFilter() {
      this.authorLoading = true;
      this.filter = "";
      this.filter_by = "";
      this.$router.push({ name: this.refs });
    },

    // remove LocalStorage
    removeLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("avatar");
      localStorage.removeItem("role");
    },

    // Fetch Export to Excel, CSV
    async fetchExport() {
      const res = await this.axios.post(
        window.baseURL +
          "/" +
          this.refs +
          "/export?id=" +
          this.selected +
          "&locale=" +
          this.locale +
          "&from=" +
          this.row.from +
          "&to=" +
          this.row.to +
          "&model=" +
          this.row.model +
          "&type=" +
          this.row.model 
      );
      return res.data.rows;
    },
    startDownload() {
      this.exportLoading = true;
    },
    finishDownload() {
      this.exportLoading = false;
      iziToast.success({
        icon: "ti-check",
        title: "",
        message: "تم إنشاء الملف بنجاح",
      });
    },

    /** Bulk Actions **/
    // ON Select
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.rows) {
          this.selected.push(this.rows[i].id);
        }
      }
    },

    // Multi Update Status
    multiActive() {
      if (this.selected.length == 0) {
        alert("No item(s) selected");
      } else {
        this.plural = "(s)";
        this.bulkLoading = true;
        this.activate(this.selected.join());
      }
    },

    multiInactive() {
      if (this.selected.length == 0) {
        alert("No item(s) selected");
      } else {
        this.plural = "(s)";
        this.bulkLoading = true;
        this.inactivate(this.selected.join());
      }
    },

    // Update Status
    activate(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/active/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "ti-check",
            title: "",
            message: "تم التنشيط بنجاح",
          });
        })
        .catch(() => {
          this.fetchData();
          iziToast.error({
            icon: "ti-na",
            title: "",
            message: "حدث خطأ في الخادم, من فضلك حاول مرة آخري",
          });
        })
        .finally(() => {});
    },

    inactivate(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/inactive/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "ti-check",
            title: "",
            message: "تم إلغاء التنشيط بنجاح",
          });
        })
        .catch(() => {
          this.fetchData();
          iziToast.error({
            icon: "ti-na",
            title: "",
            message: "حدث خطأ في الخادم, من فضلك حاول مرة آخري",
          });
        })
        .finally(() => {});
    },

    // Multi Move to Trash
    multiMoveToTrash() {
      if (this.selected.length == 0) {
        alert("No item(s) selected");
      } else {
        this.plural = "(s)";
        this.bulkLoading = true;
        this.moveToTrash(this.selected.join());
      }
    },
    // Move to Trash
    moveToTrash(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/trash/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "ti-check",
            title: "",
            message: "تم النقل إلي السلة بنجاح",
          });
        })
        .catch(() => {
          this.fetchData();
          iziToast.error({
            icon: "ti-na",
            title: "",
            message: "حدث خطأ في الخادم, من فضلك حاول مرة آخري",
          });
        })
        .finally(() => {});
    },

    // Multi Restore from Trash
    multiRestoreFromTrash() {
      if (this.selected.length == 0) {
        alert("No item(s) selected");
      } else {
        this.plural = "(s)";
        this.bulkLoading = true;
        this.restoreFromTrash(this.selected.join());
      }
    },
    // Restore from Trash
    restoreFromTrash(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/restore/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "ti-check",
            title: "",
            message: "تم الإسترجاع من السلة بنجاح",
          });
        })
        .catch(() => {
          this.fetchData();
          iziToast.error({
            icon: "ti-na",
            title: "",
            message: "حدث خطأ في الخادم, من فضلك حاول مرة آخري",
          });
        })
        .finally(() => {});
    },

    // Multi Delete Permanently
    multiDeletePermanently() {
      if (this.selected.length == 0) {
        alert("No item(s) selected");
      } else {
        this.plural = "(s)";
        this.bulkLoading = true;
        this.deletePermanently(this.selected.join());
      }
    },

    // Delete Permanently
    deletePermanently(id) {
      if (confirm("Are You Sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.refs + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData();
            iziToast.success({
              icon: "ti-check",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch(() => {
            this.fetchData();
            iziToast.error({
              icon: "ti-na",
              title: "",
              message: "حدث خطأ في الخادم, من فضلك حاول مرة آخري",
            });
          })
          .finally(() => {});
      } else {
        // In case Cancel Alert
        this.fetchData();
      }
    },
    /** END Bulk Actions **/
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
table thead {
  background-color: #515365 !important;
}
table tfoot {
  display: none !important;
}
.table > thead > tr > th {
  color: white !important;
}
</style>