import Vue from 'vue'
import Router from 'vue-router'
//import store from '../store';

Vue.use(Router)
export default new Router({
    mode: 'history',
    base: '/',
    fallback: true,
    routes: [

        /** Dashboard **/

        // Dashboard
        { path: '/', name: 'dashboard', component: require('../components/dashboard/App.vue').default, beforeEnter: requireAuth },

        // Applications
        { path: '/applications', name: 'applications', component: require('../components/applications/List.vue').default, beforeEnter: requireAuth },

        // Auth
        { path: '/auth/login', name: 'login', component: require('../components/auth/Login.vue').default, beforeEnter: requireUnAuth },
        { path: '/forget/password', name: 'forget', component: require('../components/auth/Forget.vue').default, beforeEnter: requireUnAuth },
        { path: '/reset/password', name: 'reset', component: require('../components/auth/Reset.vue').default, beforeEnter: requireAuth },

        // About
        { path: '/about', name: 'about', component: require('../components/about/List.vue').default, beforeEnter: requireAuth },
        { path: '/about/status/:status', name: 'status-about', component: require('../components/about/List.vue').default, beforeEnter: requireAuth },
        { path: '/about/create', name: 'create-about', component: require('../components/about/Create.vue').default, beforeEnter: requireAuth },
        { path: '/about/edit/:id', name: 'edit-about', component: require('../components/about/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/about/information', name: 'information-about', component: require('../components/about/Information.vue').default, beforeEnter: requireAuth },

        // Gallery
        { path: '/gallery', name: 'gallery', component: require('../components/gallery/List.vue').default, beforeEnter: requireAuth },
        { path: '/gallery/status/:status', name: 'status-gallery', component: require('../components/gallery/List.vue').default, beforeEnter: requireAuth },
        { path: '/gallery/create', name: 'create-gallery', component: require('../components/gallery/Create.vue').default, beforeEnter: requireAuth },
        { path: '/gallery/edit/:id', name: 'edit-gallery', component: require('../components/gallery/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/gallery/information', name: 'information-gallery', component: require('../components/gallery/Information.vue').default, beforeEnter: requireAuth },

        // Mutimedia
        { path: '/multimedia', name: 'multimedia', component: require('../components/multimedia/List.vue').default, beforeEnter: requireAuth },
        { path: '/multimedia/status/:status', name: 'status-multimedia', component: require('../components/multimedia/List.vue').default, beforeEnter: requireAuth },
        { path: '/multimedia/create', name: 'create-multimedia', component: require('../components/multimedia/Create.vue').default, beforeEnter: requireAuth },
        { path: '/multimedia/edit/:id', name: 'edit-multimedia', component: require('../components/multimedia/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/multimedia/information', name: 'information-multimedia', component: require('../components/multimedia/Information.vue').default, beforeEnter: requireAuth },

        // Thumbnail
        { path: '/thumbnail', name: 'thumbnail', component: require('../components/thumbnails/List.vue').default, beforeEnter: requireAuth },
        { path: '/thumbnail/status/:status', name: 'status-thumbnail', component: require('../components/thumbnails/List.vue').default, beforeEnter: requireAuth },
        { path: '/thumbnail/create', name: 'create-thumbnail', component: require('../components/thumbnails/Create.vue').default, beforeEnter: requireAuth },
        { path: '/thumbnail/edit/:id', name: 'edit-thumbnail', component: require('../components/thumbnails/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/thumbnail/information', name: 'information-thumbnail', component: require('../components/thumbnails/Information.vue').default, beforeEnter: requireAuth },


        // Accounts
        { path: '/accounts', name: 'accounts', component: require('../components/accounts/List.vue').default, beforeEnter: requireAuth },
        { path: '/accounts/status/:status', name: 'status-accounts', component: require('../components/accounts/List.vue').default, beforeEnter: requireAuth },
        { path: '/accounts/filter/:filter_by/:filter', name: 'filter-accounts', component: require('../components/accounts/List.vue').default, beforeEnter: requireAuth },
        { path: '/accounts/create', name: 'create-accounts', component: require('../components/accounts/Create.vue').default, beforeEnter: requireAuth },
        { path: '/accounts/edit/:id', name: 'edit-accounts', component: require('../components/accounts/Edit.vue').default, beforeEnter: requireAuth },

        // Categories
        { path: '/categories', name: 'categories', component: require('../components/categories/List.vue').default, beforeEnter: requireAuth },
        { path: '/categories/status/:status', name: 'status-categories', component: require('../components/categories/List.vue').default, beforeEnter: requireAuth },
        { path: '/categories/filter/:filter_by/:filter', name: 'filter-categories', component: require('../components/categories/List.vue').default, beforeEnter: requireAuth },
        { path: '/categories/create', name: 'create-categories', component: require('../components/categories/Create.vue').default, beforeEnter: requireAuth },
        { path: '/categories/edit/:id', name: 'edit-categories', component: require('../components/categories/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/categories/information', name: 'information-categories', component: require('../components/categories/Information.vue').default, beforeEnter: requireAuth },


        // Foreign Offices
        { path: '/foreign_offices', name: 'foreign_offices', component: require('../components/foreignOffices/List.vue').default, beforeEnter: requireAuth },
        { path: '/foreign_offices/status/:status', name: 'status-foreign_offices', component: require('../components/foreignOffices/List.vue').default, beforeEnter: requireAuth },



        // Countries
        { path: '/countries', name: 'countries', component: require('../components/countries/List.vue').default, beforeEnter: requireAuth },
        { path: '/countries/status/:status', name: 'status-countries', component: require('../components/countries/List.vue').default, beforeEnter: requireAuth },
        { path: '/countries/filter/:filter_by/:filter', name: 'filter-countries', component: require('../components/countries/List.vue').default, beforeEnter: requireAuth },
        { path: '/countries/create', name: 'create-countries', component: require('../components/countries/Create.vue').default, beforeEnter: requireAuth },
        { path: '/countries/edit/:id', name: 'edit-countries', component: require('../components/countries/Edit.vue').default, beforeEnter: requireAuth },

        { path: '/countries/:parent_id/:parent_name', name: 'cities-countries', component: require('../components/cities/List.vue').default, beforeEnter: requireAuth },
        { path: '/countries/:parent_id/:parent_name/status/:status', name: 'cities-status-countries', component: require('../components/cities/List.vue').default, beforeEnter: requireAuth },
        { path: '/countries/:parent_id/:parent_name/filter/:filter_by/:filter', name: 'cities-filter-countries', component: require('../components/cities/List.vue').default, beforeEnter: requireAuth },
        { path: '/countries/:parent_id/:parent_name/create', name: 'cities-create-countries', component: require('../components/cities/Create.vue').default, beforeEnter: requireAuth },
        { path: '/countries/:parent_id/:parent_name/edit/:id', name: 'cities-edit-countries', component: require('../components/cities/Edit.vue').default, beforeEnter: requireAuth },

        // Contributors
        { path: '/contributors', name: 'contributors', component: require('../components/contributors/List.vue').default, beforeEnter: requireAuth },
        { path: '/contributors/status/:status', name: 'status-contributors', component: require('../components/contributors/List.vue').default, beforeEnter: requireAuth },
        { path: '/contributors/filter/:filter_by/:filter', name: 'filter-contributors', component: require('../components/contributors/List.vue').default, beforeEnter: requireAuth },
        { path: '/contributors/create', name: 'create-contributors', component: require('../components/contributors/Create.vue').default, beforeEnter: requireAuth },
        { path: '/contributors/edit/:id', name: 'edit-contributors', component: require('../components/contributors/Edit.vue').default, beforeEnter: requireAuth },

        // Donations
        { path: '/donations', name: 'donations', component: require('../components/donations/List.vue').default, beforeEnter: requireAuth },
        { path: '/donations/:id', name: 'show-donations', component: require('../components/donations/Show.vue').default, beforeEnter: requireAuth },
        { path: '/donations/receipt/:id', name: 'show-donation-receipt', component: require('../components/donations/receipt.vue').default, beforeEnter: requireAuth },

        // Donation Types
        { path: '/types', name: 'types', component: require('../components/donationTypes/List.vue').default, beforeEnter: requireAuth },
        { path: '/types/status/:status', name: 'status-types', component: require('../components/donationTypes/List.vue').default, beforeEnter: requireAuth },
        { path: '/types/filter/:filter_by/:filter', name: 'filter-types', component: require('../components/donationTypes/List.vue').default, beforeEnter: requireAuth },
        { path: '/types/create', name: 'create-types', component: require('../components/donationTypes/Create.vue').default, beforeEnter: requireAuth },
        { path: '/types/edit/:id', name: 'edit-types', component: require('../components/donationTypes/Edit.vue').default, beforeEnter: requireAuth },

        // Donation Values
        { path: '/values', name: 'values', component: require('../components/donationValues/List.vue').default, beforeEnter: requireAuth },
        { path: '/values/status/:status', name: 'status-values', component: require('../components/donationValues/List.vue').default, beforeEnter: requireAuth },
        { path: '/values/filter/:filter_by/:filter', name: 'filter-values', component: require('../components/donationValues/List.vue').default, beforeEnter: requireAuth },
        { path: '/values/create', name: 'create-values', component: require('../components/donationValues/Create.vue').default, beforeEnter: requireAuth },
        { path: '/values/edit/:id', name: 'edit-values', component: require('../components/donationValues/Edit.vue').default, beforeEnter: requireAuth },


        // Donation Vouchers
        { path: '/donation-vouchers', name: 'donation-vouchers', component: require('../components/donationVouchers/List.vue').default, beforeEnter: requireAuth },
        { path: '/donation-vouchers/create', name: 'create-donation-vouchers', component: require('../components/donationVouchers/Create.vue').default, beforeEnter: requireAuth },
        { path: '/donation-vouchers/edit/:id', name: 'edit-donation-vouchers', component: require('../components/donationVouchers/Edit.vue').default, beforeEnter: requireAuth },

        // Endowment
        { path: '/endowments', name: 'endowments', component: require('../components/endowments/List.vue').default, beforeEnter: requireAuth },
        { path: '/endowments/status/:status', name: 'status-endowments', component: require('../components/endowments/List.vue').default, beforeEnter: requireAuth },
        { path: '/endowments/filter/:filter_by/:filter', name: 'filter-endowments', component: require('../components/endowments/List.vue').default, beforeEnter: requireAuth },
        { path: '/endowments/create', name: 'create-endowments', component: require('../components/endowments/Create.vue').default, beforeEnter: requireAuth },
        { path: '/endowments/edit/:id', name: 'edit-endowments', component: require('../components/endowments/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/endowments/information', name: 'information-endowments', component: require('../components/endowments/Information.vue').default, beforeEnter: requireAuth },


        // Inbox
        { path: '/inbox', name: 'inbox', component: require('../components/inbox/List.vue').default, beforeEnter: requireAuth },
        { path: '/inbox/status/:status', name: 'status-inbox', component: require('../components/inbox/List.vue').default, beforeEnter: requireAuth },
        { path: '/inbox/filter/:filter_by/:filter', name: 'filter-inbox', component: require('../components/inbox/List.vue').default, beforeEnter: requireAuth },
        { path: '/inbox/edit/:id', name: 'show-inbox', component: require('../components/inbox/Show.vue').default, beforeEnter: requireAuth },
        { path: '/inbox/information', name: 'information-inbox', component: require('../components/inbox/Information.vue').default, beforeEnter: requireAuth },



        // Orphans
        { path: '/orphans', name: 'orphans', component: require('../components/orphans/List.vue').default, beforeEnter: requireAuth },
        { path: '/orphans/status/:status', name: 'status-orphans', component: require('../components/orphans/List.vue').default, beforeEnter: requireAuth },
        { path: '/orphans/filter/:filter_by/:filter', name: 'filter-orphans', component: require('../components/orphans/List.vue').default, beforeEnter: requireAuth },
        { path: '/orphans/create', name: 'create-orphans', component: require('../components/orphans/Create.vue').default, beforeEnter: requireAuth },
        { path: '/orphans/edit/:id', name: 'edit-orphans', component: require('../components/orphans/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/orphans/information', name: 'information-orphans', component: require('../components/orphans/Information.vue').default, beforeEnter: requireAuth },


        // Postgraduate
        { path: '/postgraduates', name: 'postgraduates', component: require('../components/postgraduates/List.vue').default, beforeEnter: requireAuth },
        { path: '/postgraduates/status/:status', name: 'status-postgraduates', component: require('../components/postgraduates/List.vue').default, beforeEnter: requireAuth },
        { path: '/postgraduates/filter/:filter_by/:filter', name: 'filter-postgraduates', component: require('../components/postgraduates/List.vue').default, beforeEnter: requireAuth },
        { path: '/postgraduates/create', name: 'create-postgraduates', component: require('../components/postgraduates/Create.vue').default, beforeEnter: requireAuth },
        { path: '/postgraduates/edit/:id', name: 'edit-postgraduates', component: require('../components/postgraduates/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/postgraduates/information', name: 'information-postgraduates', component: require('../components/postgraduates/Information.vue').default, beforeEnter: requireAuth },


        // Pages
        { path: '/pages', name: 'pages', component: require('../components/pages/List.vue').default, beforeEnter: requireAuth },
        { path: '/pages/status/:status', name: 'status-pages', component: require('../components/pages/List.vue').default, beforeEnter: requireAuth },
        { path: '/pages/filter/:filter_by/:filter', name: 'filter-pages', component: require('../components/pages/List.vue').default, beforeEnter: requireAuth },
        { path: '/pages/create', name: 'create-pages', component: require('../components/pages/Create.vue').default, beforeEnter: requireAuth },
        { path: '/pages/edit/:id', name: 'edit-pages', component: require('../components/pages/Edit.vue').default, beforeEnter: requireAuth },

        // Preachers
        { path: '/preachers', name: 'preachers', component: require('../components/preachers/List.vue').default, beforeEnter: requireAuth },
        { path: '/preachers/status/:status', name: 'status-preachers', component: require('../components/preachers/List.vue').default, beforeEnter: requireAuth },
        { path: '/preachers/filter/:filter_by/:filter', name: 'filter-preachers', component: require('../components/preachers/List.vue').default, beforeEnter: requireAuth },
        { path: '/preachers/create', name: 'create-preachers', component: require('../components/preachers/Create.vue').default, beforeEnter: requireAuth },
        { path: '/preachers/edit/:id', name: 'edit-preachers', component: require('../components/preachers/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/preachers/information', name: 'information-preachers', component: require('../components/preachers/Information.vue').default, beforeEnter: requireAuth },

        // Projects
        { path: '/projects', name: 'projects', component: require('../components/projects/List.vue').default, beforeEnter: requireAuth },
        { path: '/projects/status/:status', name: 'status-projects', component: require('../components/projects/List.vue').default, beforeEnter: requireAuth },
        { path: '/projects/filter/:filter_by/:filter', name: 'filter-projects', component: require('../components/projects/List.vue').default, beforeEnter: requireAuth },
        { path: '/projects/create', name: 'create-projects', component: require('../components/projects/Create.vue').default, beforeEnter: requireAuth },
        { path: '/projects/edit/:id', name: 'edit-projects', component: require('../components/projects/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/projects/information', name: 'information-projects', component: require('../components/projects/Information.vue').default, beforeEnter: requireAuth },


        // Relief
        { path: '/reliefs', name: 'reliefs', component: require('../components/reliefs/List.vue').default, beforeEnter: requireAuth },
        { path: '/reliefs/status/:status', name: 'status-reliefs', component: require('../components/reliefs/List.vue').default, beforeEnter: requireAuth },
        { path: '/reliefs/filter/:filter_by/:filter', name: 'filter-reliefs', component: require('../components/reliefs/List.vue').default, beforeEnter: requireAuth },
        { path: '/reliefs/create', name: 'create-reliefs', component: require('../components/reliefs/Create.vue').default, beforeEnter: requireAuth },
        { path: '/reliefs/edit/:id', name: 'edit-reliefs', component: require('../components/reliefs/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/reliefs/information', name: 'information-reliefs', component: require('../components/reliefs/Information.vue').default, beforeEnter: requireAuth },

        // Reports
        { path: '/reports', name: 'reports', component: require('../components/reports/List.vue').default, beforeEnter: requireAuth },
        { path: '/reports/type/:type', name: 'type-reports', component: require('../components/reports/List.vue').default, beforeEnter: requireAuth },
        { path: '/reports/general/donations', name: 'general-donations-reports', component: require('../components/reports/General.vue').default, beforeEnter: requireAuth },
        { path: '/reports/all/donations', name: 'all-donations', component: require('../components/reports/All.vue').default, beforeEnter: requireAuth },
        { path: '/reports/donation/:type', name: 'donation-reports', component: require('../components/reports/Donation.vue').default, beforeEnter: requireAuth },
        { path: '/reports/groupByDonations', name: 'groupBy-donations', component: require('../components/reports/GroupBy.vue').default, beforeEnter: requireAuth },
        { path: '/reports/share/:type', name: 'share-reports', component: require('../components/reports/Share.vue').default, beforeEnter: requireAuth },
        { path: '/reports/status/:status', name: 'status-reports', component: require('../components/reports/List.vue').default, beforeEnter: requireAuth },
        { path: '/reports/filter/:filter_by/:filter', name: 'filter-reports', component: require('../components/reports/List.vue').default, beforeEnter: requireAuth },
        // { path: '/reports/create', name: 'create-reports', component: require('../components/reports/Create.vue').default, beforeEnter: requireAuth },
        // { path: '/reports/edit/:id', name: 'edit-reports', component: require('../components/reports/Edit.vue').default, beforeEnter: requireAuth },

        // Daily Report
        { path: '/periodicReports', name: 'periodicReports', component: require('../components/periodicReports/List.vue').default, beforeEnter: requireAuth },
        { path: '/periodicReports/status/:status', name: 'status-periodicReports', component: require('../components/periodicReports/List.vue').default, beforeEnter: requireAuth },
        { path: '/periodicReports/create', name: 'create-periodicReports', component: require('../components/periodicReports/Create.vue').default, beforeEnter: requireAuth },
        { path: '/periodicReports/edit/:id', name: 'edit-periodicReports', component: require('../components/periodicReports/Edit.vue').default, beforeEnter: requireAuth },


        // Roles
        { path: '/roles', name: 'roles', component: require('../components/roles/List.vue').default, beforeEnter: requireAuth },
        { path: '/roles/status/:status', name: 'status-roles', component: require('../components/roles/List.vue').default, beforeEnter: requireAuth },
        { path: '/roles/filter/:filter_by/:filter', name: 'filter-roles', component: require('../components/roles/List.vue').default, beforeEnter: requireAuth },
        { path: '/roles/create', name: 'create-roles', component: require('../components/roles/Create.vue').default, beforeEnter: requireAuth },
        { path: '/roles/edit/:id', name: 'edit-roles', component: require('../components/roles/Edit.vue').default, beforeEnter: requireAuth },

        // Services
        { path: '/services', name: 'services', component: require('../components/services/List.vue').default, beforeEnter: requireAuth },
        { path: '/services/status/:status', name: 'status-services', component: require('../components/services/List.vue').default, beforeEnter: requireAuth },
        { path: '/services/filter/:filter_by/:filter', name: 'filter-services', component: require('../components/services/List.vue').default, beforeEnter: requireAuth },
        { path: '/services/create', name: 'create-services', component: require('../components/services/Create.vue').default, beforeEnter: requireAuth },
        { path: '/services/edit/:id', name: 'edit-services', component: require('../components/services/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/services/information', name: 'information-services', component: require('../components/services/Information.vue').default, beforeEnter: requireAuth },


        // Settings
        { path: '/settings', name: 'settings', component: require('../components/settings/List.vue').default, beforeEnter: requireAuth },
        { path: '/settings/:slug', name: 'show-settings', component: require('../components/settings/Show.vue').default, beforeEnter: requireAuth },
        { path: '/urgent', name: 'urgent', component: require('../components/settings/Urgent.vue').default, beforeEnter: requireAuth },
        { path: '/donateNow', name: 'donateNow', component: require('../components/settings/DonateNow.vue').default, beforeEnter: requireAuth },


        // Socials
        { path: '/socials', name: 'socials', component: require('../components/socials/List.vue').default, beforeEnter: requireAuth },
        { path: '/socials/status/:status', name: 'status-socials', component: require('../components/socials/List.vue').default, beforeEnter: requireAuth },
        { path: '/socials/filter/:filter_by/:filter', name: 'filter-socials', component: require('../components/socials/List.vue').default, beforeEnter: requireAuth },
        { path: '/socials/create', name: 'create-socials', component: require('../components/socials/Create.vue').default, beforeEnter: requireAuth },
        { path: '/socials/edit/:id', name: 'edit-socials', component: require('../components/socials/Edit.vue').default, beforeEnter: requireAuth },

        // Sliders
        { path: '/sliders', name: 'sliders', component: require('../components/sliders/List.vue').default, beforeEnter: requireAuth },
        { path: '/sliders/status/:status', name: 'status-sliders', component: require('../components/sliders/List.vue').default, beforeEnter: requireAuth },
        { path: '/sliders/filter/:filter_by/:filter', name: 'filter-sliders', component: require('../components/sliders/List.vue').default, beforeEnter: requireAuth },
        { path: '/sliders/create', name: 'create-sliders', component: require('../components/sliders/Create.vue').default, beforeEnter: requireAuth },
        { path: '/sliders/edit/:id', name: 'edit-sliders', component: require('../components/sliders/Edit.vue').default, beforeEnter: requireAuth },

        // Staffs
        { path: '/staffs', name: 'staffs', component: require('../components/staffs/List.vue').default, beforeEnter: requireAuth },
        { path: '/staffs/status/:status', name: 'status-staffs', component: require('../components/staffs/List.vue').default, beforeEnter: requireAuth },
        { path: '/staffs/filter/:filter_by/:filter', name: 'filter-staffs', component: require('../components/staffs/List.vue').default, beforeEnter: requireAuth },
        { path: '/staffs/create', name: 'create-staffs', component: require('../components/staffs/Create.vue').default, beforeEnter: requireAuth },
        { path: '/staffs/edit/:id', name: 'edit-staffs', component: require('../components/staffs/Edit.vue').default, beforeEnter: requireAuth },


        // SMS
        { path: '/sms', name: 'sms', component: require('../components/sms/List.vue').default, beforeEnter: requireAuth },


        // Payments
        { path: '/payments/:model/:model_id', name: 'payments', component: require('../components/payments/List.vue').default, beforeEnter: requireAuth },
        { path: '/payments/:model/:model_id/status/:status', name: 'status-payments', component: require('../components/payments/List.vue').default, beforeEnter: requireAuth },
        { path: '/payments/:model/:model_id/filter/:filter_by/:filter', name: 'filter-payments', component: require('../components/payments/List.vue').default, beforeEnter: requireAuth },
        { path: '/payments/:model/:model_id/create', name: 'create-payments', component: require('../components/payments/Create.vue').default, beforeEnter: requireAuth },
        { path: '/payments/:model/:model_id/edit/:id', name: 'edit-payments', component: require('../components/payments/Edit.vue').default, beforeEnter: requireAuth },


        // share links
        { path: '/shares/:model/:model_id', name: 'shares', component: require('../components/shares/List.vue').default, beforeEnter: requireAuth },
        { path: '/shares/:model/:model_id/status/:status', name: 'status-shares', component: require('../components/shares/List.vue').default, beforeEnter: requireAuth },
        { path: '/shares/:model/:model_id/filter/:filter_by/:filter', name: 'filter-shares', component: require('../components/shares/List.vue').default, beforeEnter: requireAuth },
        { path: '/shares/:model/:model_id/create', name: 'create-shares', component: require('../components/shares/Create.vue').default, beforeEnter: requireAuth },
        { path: '/shares/:model/:model_id/edit/:id', name: 'edit-shares', component: require('../components/shares/Edit.vue').default, beforeEnter: requireAuth },

        // Cash Donations
        { path: '/cashDonations', name: 'cashDonations', component: require('../components/cashDonations/List.vue').default, beforeEnter: requireAuth },
        { path: '/cashDonations/create', name: 'create-cashDonations', component: require('../components/cashDonations/Create.vue').default, beforeEnter: requireAuth },


        // Volunteers
        { path: '/volunteers', name: 'volunteers', component: require('../components/volunteers/List.vue').default, beforeEnter: requireAuth },
        { path: '/volunteers/status/:status', name: 'status-volunteers', component: require('../components/volunteers/List.vue').default, beforeEnter: requireAuth },
        { path: '/volunteers/filter/:filter_by/:filter', name: 'filter-volunteers', component: require('../components/volunteers/List.vue').default, beforeEnter: requireAuth },
        { path: '/volunteers/create', name: 'create-volunteers', component: require('../components/volunteers/Create.vue').default, beforeEnter: requireAuth },
        { path: '/volunteers/edit/:id', name: 'edit-volunteers', component: require('../components/volunteers/Edit.vue').default, beforeEnter: requireAuth },


        // VolunteersRequests
        { path: '/volunteersRequests', name: 'volunteersRequests', component: require('../components/volunteersRequests/List.vue').default, beforeEnter: requireAuth },


        // Teams
        { path: '/teams', name: 'teams', component: require('../components/teams/List.vue').default, beforeEnter: requireAuth },
        { path: '/teams/status/:status', name: 'status-teams', component: require('../components/teams/List.vue').default, beforeEnter: requireAuth },
        { path: '/teams/filter/:filter_by/:filter', name: 'filter-teams', component: require('../components/teams/List.vue').default, beforeEnter: requireAuth },
        { path: '/teams/create', name: 'create-teams', component: require('../components/teams/Create.vue').default, beforeEnter: requireAuth },
        { path: '/teams/edit/:id', name: 'edit-teams', component: require('../components/teams/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/teams/information', name: 'information-teams', component: require('../components/teams/Information.vue').default, beforeEnter: requireAuth },

        // News
        { path: '/news', name: 'news', component: require('../components/news/List.vue').default, beforeEnter: requireAuth },
        { path: '/news/status/:status', name: 'status-news', component: require('../components/news/List.vue').default, beforeEnter: requireAuth },
        { path: '/news/filter/:filter_by/:filter', name: 'filter-news', component: require('../components/news/List.vue').default, beforeEnter: requireAuth },
        { path: '/news/create', name: 'create-news', component: require('../components/news/Create.vue').default, beforeEnter: requireAuth },
        { path: '/news/edit/:id', name: 'edit-news', component: require('../components/news/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/news/information', name: 'information-news', component: require('../components/news/Information.vue').default, beforeEnter: requireAuth },


        // Project Nomination
        { path: '/projectNomination', name: 'projectNomination', component: require('../components/projectNomination/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectNomination/status/:status', name: 'status-projectNomination', component: require('../components/projectNomination/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectNomination/filter/:filter_by/:filter', name: 'filter-projectNomination', component: require('../components/projectNomination/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectNomination/create', name: 'create-projectNomination', component: require('../components/projectNomination/Create.vue').default, beforeEnter: requireAuth },
        { path: '/projectNomination/edit/:id', name: 'edit-projectNomination', component: require('../components/projectNomination/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/projectNomination/information', name: 'information-projectNomination', component: require('../components/projectNomination/Information.vue').default, beforeEnter: requireAuth },

        // Project Nomination Values
        { path: '/projectsNominationValue', name: 'projectsNominationValue', component: require('../components/projectNominationValue/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectsNominationValue/status/:status', name: 'status-projectsNominationValue', component: require('../components/projectNominationValue/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectsNominationValue/filter/:filter_by/:filter', name: 'filter-projectsNominationValue', component: require('../components/projectNominationValue/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectsNominationValue/create', name: 'create-projectsNominationValue', component: require('../components/projectNominationValue/Create.vue').default, beforeEnter: requireAuth },
        { path: '/projectsNominationValue/edit/:id', name: 'edit-projectsNominationValue', component: require('../components/projectNominationValue/Edit.vue').default, beforeEnter: requireAuth },

        // Project Nomination Order
        { path: '/projectsNominationOrders', name: 'projectsNominationOrders', component: require('../components/projectNominationOrders/List.vue').default, beforeEnter: requireAuth },
        { path: '/projectsNominationOrders/:id', name: 'show-projectsNominationOrders', component: require('../components/projectNominationOrders/Show.vue').default, beforeEnter: requireAuth },

        // Testimonials
        { path: '/testimonials', name: 'testimonials', component: require('../components/testimonials/List.vue').default, beforeEnter: requireAuth },
        { path: '/testimonials/status/:status', name: 'status-testimonials', component: require('../components/testimonials/List.vue').default, beforeEnter: requireAuth },
        { path: '/testimonials/filter/:filter_by/:filter', name: 'filter-testimonials', component: require('../components/testimonials/List.vue').default, beforeEnter: requireAuth },
        { path: '/testimonials/create', name: 'create-testimonials', component: require('../components/testimonials/Create.vue').default, beforeEnter: requireAuth },
        { path: '/testimonials/edit/:id', name: 'edit-testimonials', component: require('../components/testimonials/Edit.vue').default, beforeEnter: requireAuth },
        { path: '/testimonials/information', name: 'information-testimonials', component: require('../components/testimonials/Information.vue').default, beforeEnter: requireAuth },

        // Extra
        { path: '/forbidden', name: 'forbidden', component: require('../components/extra/403.vue').default },
        { path: '/*', name: 'not-found', component: require('../components/extra/404.vue').default }

    ]
})


function requireAuth(to, from, next) {
    // store.dispatch('fetchAccessToken');
    // if (!store.state.accessToken) { next('/auth/login'); } 
    // else { next(); }
    if (!localStorage.getItem('access_token')) { next('/auth/login'); } else { next(); }
    //next();
}

function requireUnAuth(to, from, next) {
    //store.dispatch('fetchAccessToken');
    // if (store.state.accessToken) { next('/'); } 
    // else { next(); }
    if (localStorage.getItem('access_token')) { next('/'); } else { next(); }
    //next();
}