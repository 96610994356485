<template>
  <div class="">
    <!-- Header START -->
    <div class="header navbar navbar-shadow">
      <div class="header-container">
        <div class="nav-logo">
          <router-link :to="{ name: 'dashboard' }">
            <div
              class="logo logo-dark"
              style="
                background-image: url('/assets/images/logo/children-charity-logo.png');
                background-position: center;
                background-size: 100%;
              "
            ></div>
            <div
              class="logo logo-white"
              style="
                background-image: url('/assets/images/logo/children-charity-logo.png');
                background-position: center;
                background-size: 100%;
              "
            ></div>
          </router-link>
        </div>
        <ul class="nav-left">
          <li>
            <a
              class="sidenav-fold-toggler"
              @click="toggleDropdownBar"
              href="javascript:;"
            >
              <i class="mdi mdi-menu"></i>
            </a>
            <a
              class="sidenav-expand-toggler"
              @click="toggleDropdownBar"
              href="javascript:;"
            >
              <i class="mdi mdi-menu"></i>
            </a>
          </li>
          <li class="search-box src-box" @click="toggleSearch">
            <a class="search-toggle" href="javascript:;">
              <i class="search-icon mdi mdi-magnify"></i>
              <i class="search-icon-close mdi mdi-close-circle-outline"></i>
            </a>
          </li>
          <li class="search-input src-txt">
            <form @submit.prevent="onSearch">
              <input
                class="form-control"
                type="text"
                v-model="search"
                placeholder="Type to search..."
              />
            </form>
          </li>
        </ul>
        <ul class="nav-right">
          <!-- Applications -->
          <!-- <li class="dropdown dropdown-animated scale-left">
                            <router-link :to="{ name: 'applications' }">
                                <i class="mdi mdi-briefcase-check"></i>
                            </router-link>
                        </li> -->
          <!-- End Applications -->

          <!-- <li class="notifications dropdown dropdown-animated scale-left">
                            <span class="counter">2</span>
                            <a href="" class="dropdown-toggle" data-toggle="dropdown">
                                <i class="mdi mdi-bell-ring-outline"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-lg p-v-0">
                                <li class="p-v-15 p-h-20 border bottom text-dark">
                                    <h5 class="m-b-0">
                                        <i class="mdi mdi-bell-ring-outline p-r-10"></i>
                                        <span>Notifications</span>
                                    </h5>
                                </li>
                                <li>
                                    <ul class="list-media overflow-y-auto relative scrollable" style="max-height: 300px">
                                        <li class="list-item border bottom">
                                            <a href="javascript:void(0);" class="media-hover p-15">
                                                <div class="media-img">
                                                    <div class="icon-avatar bg-primary">
                                                        <i class="ti-settings"></i>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <span class="title">
                                                        System shutdown
                                                    </span>
                                                    <span class="sub-title">8 min ago</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="list-item border bottom">
                                            <a href="javascript:void(0);" class="media-hover p-15">
                                                <div class="media-img">
                                                    <div class="icon-avatar bg-success">
                                                        <i class="ti-user"></i>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <span class="title">
                                                        New User Registered
                                                    </span>
                                                    <span class="sub-title">12 min ago</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="list-item border bottom">
                                            <a href="javascript:void(0);" class="media-hover p-15">
                                                <div class="media-img">
                                                    <div class="icon-avatar bg-warning">
                                                        <i class="ti-file"></i>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <span class="title">
                                                        New Attacthemnet
                                                    </span>
                                                    <span class="sub-title">12 min ago</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="list-item border bottom">
                                            <a href="javascript:void(0);" class="media-hover p-15">
                                                <div class="media-img">
                                                    <div class="icon-avatar bg-info">
                                                        <i class="ti-shopping-cart"></i>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <span class="title">
                                                        New Order Received
                                                    </span>
                                                    <span class="sub-title">12 min ago</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="p-v-15 p-h-20 text-center">
                                    <span>
                                        <a href="" class="text-gray">Check all notifications <i class="ei-right-chevron p-l-5 font-size-10"></i></a>
                                    </span>
                                </li>
                            </ul>
                        </li> -->
          <li
            class="user-profile dropdown dropdown-animated"
            :class="locale == 'ar' ? 'scale-right' : 'scale-left'"
          >
            <a
              href="javascript:;"
              class="dropdown-toggle"
              data-toggle="dropdown"
            >
              <img
                class="profile-img img-fluid"
                :src="auth.avatar"
                alt="avatar"
                style="width: 35px; height: 35px"
              />
            </a>
            <ul
              class="dropdown-menu dropdown-md"
              style="padding: 5px; direction: rtl; text-align: right"
            >
              <li v-if="auth.role == 'root'">
                <router-link :to="'/staffs'">
                  <i class="ti-user"></i>
                  <span> {{ $t("app.profile") }} </span>
                </router-link>
              </li>
              <li v-if="auth.role != 'root'">
                <router-link :to="'/staffs/edit/' + auth.user_id">
                  <i class="ti-user"></i>
                  <span> {{ $t("app.profile") }} </span>
                </router-link>
              </li>
              <li>
                <a href="javascript:;" @click="logout">
                  <i class="ti-power-off"></i>
                  <span> {{ $t("app.logout") }} </span>
                </a>
              </li>
            </ul>
          </li>
          <li class="m-r-10">
            <a class="" @click="toggleSideBar" href="javascript:;">
              <i class="mdi mdi-format-indent-decrease"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Header END -->

    <Navigation></Navigation>
  </div>
</template>

<script>
import Navigation from "../layouts/Navigation.vue";
export default {
  name: "Header",
  components: {
    Navigation,
  },
  data() {
    return {
      auth: {
        access_token: "",
        user_name: "",
        user_id: "",
        avatar: "",
        role: "",
      },
      search: "",
      isDropDown: false,
      isSideOpen: false,

      locale: "ar",
    };
  },
  mounted() {},
  created() {
    // Auth
    if (localStorage.getItem("locale")) {
      this.locale = localStorage.getItem("locale");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("user_name")) {
      this.auth.user_name = localStorage.getItem("user_name");
    }
    if (localStorage.getItem("user_id")) {
      this.auth.user_id = localStorage.getItem("user_id");
    }
    if (localStorage.getItem("avatar")) {
      this.auth.avatar = localStorage.getItem("avatar");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
  },
  methods: {
    // on Search
    onSearch() {
      this.$emit("headerToChild", this.search);
    },

    // toggle Dropdown
    toggleDropdownBar() {
      if (this.isDropDown) {
        let app = document.querySelectorAll("div.app");
        let appx = app[app.length - 1];
        appx.classList.remove("side-nav-folded");
        this.isDropDown = false;
      } else {
        let app = document.querySelectorAll("div.app");
        let appx = app[app.length - 1];
        appx.classList.add("side-nav-folded");
        this.isDropDown = true;
      }
    },

    // toggle Dropdown
    toggleSideBar() {
      if (this.isSideOpen) {
        let app = document.querySelectorAll("div.app");
        let appx = app[app.length - 1];
        appx.classList.remove("quick-view-expand");
        this.isSideOpen = false;
      } else {
        let app = document.querySelectorAll("div.app");
        let appx = app[app.length - 1];
        appx.classList.add("quick-view-expand");
        this.isSideOpen = true;
      }
    },

    //
    toggleSearch() {
      let app1 = document.getElementsByClassName("src-box");
      let app2 = document.getElementsByClassName("src-txt");
      let appx1 = app1[app1.length - 1];
      let appx2 = app2[app2.length - 1];
      if (appx1.classList.contains("active")) {
        appx1.classList.remove("active");
        appx2.classList.remove("active");
      } else {
        appx1.classList.add("active");
        appx2.classList.add("active");
      }
    },

    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("avatar");
      localStorage.removeItem("role");

      this.$router.push({ name: "login" });
    },

    // isTokenValid(){
    //     const token = localStorage.getItem('accessToken');
    //     if(!token) {
    //         return false;
    //     }
    //     const { exp } = JSON.parse(atob(token.split('.')[1]));
    //     return exp > Date.now().valueOf() / 1000;
    // }
  },
};
</script>

<style scoped="">
.f135 {
  font-size: 135px;
}
.f17 {
  font-size: 17px;
}
</style>
