<template>
  <div class="">
    <Header></Header>

    <div class="page-container">
      <QuickView></QuickView>

      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <!-- Header -->
          <div class="page-header">
            <!-- Title -->
            <h2
              class="header-title text-capitalize"
              :class="locale == 'ar' ? 'dir-rtl go-right' : ''"
            >
              <i class="mdi mdi-worker"></i>
              {{ $t("projects.staff") }}
            </h2>
            <router-link
              :to="{ name: refs }"
              class="btn btn-primary btn-rounded btn-bold btn-opacity"
              >Back
            </router-link>

            <!-- Role -->
            <label
              class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right"
            >
              {{ auth.role }}
            </label>
            <!-- End Role -->

            <div class="clearfix"></div>

            <!-- Breadcrumb -->
            <nav
              class="breadcrumb breadcrumb-dash"
              :class="locale == 'ar' ? 'dir-rtl' : ''"
            >
              <router-link :to="{ name: 'dashboard' }" class="breadcrumb-item">
                <i class="ti-home p-r-5"></i>
                {{ $t("projects.dashboard") }}
              </router-link>
              <router-link
                :to="{ name: refs }"
                class="breadcrumb-item text-capitalize"
                >{{ $t("projects.staff") }}
              </router-link>
              <span class="breadcrumb-item active">{{
                $t("projects.edit")
              }}</span>
            </nav>
            <!-- End Breadcrumb -->
          </div>
          <!-- End Header -->

          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="loader loader-lg ui-mtop-15"></div>
            </div>
          </div>

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
          >
            <div class="row" :class="locale == 'ar' ? 'dir-rtl' : ''">
              <div class="col-md-8">
                <!-- Content -->
                <div class="card">
                  <div class="row">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-11 ml-auto mr-auto">
                          <div class="p-v-20">
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.first_name")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.first_name"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.last_name")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.last_name"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.email")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.email"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.ccode")
                              }}</label>
                              <input
                                type="number"
                                class="form-control dir-ltr"
                                v-model="row.ccode"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.mobile")
                              }}</label>
                              <input
                                type="number"
                                class="form-control dir-ltr"
                                v-model="row.mobile"
                              />
                            </div>
                            <div class="form-group">
                              <label class="control-label">{{
                                $t("projects.password")
                              }}</label>
                              <input
                                type="password"
                                autocomplete="off"
                                class="form-control"
                                placeholder="********************"
                                v-model="row.password"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Content -->

                <!-- Button -->
                <div class="form-group">
                  <button class="btn btn-dark btn-size" :disabled="btnLoading">
                    <span v-if="btnLoading">
                      <div class="loader loader-xs loader-center"></div>
                    </span>
                    <span v-if="!btnLoading">{{ $t("projects.update") }}</span>
                  </button>
                  <button
                    type="button"
                    @click="cancel"
                    :disabled="btnLoading"
                    class="btn btn-default btn-size p-10"
                  >
                    {{ $t("projects.cancel") }}
                  </button>
                </div>
                <!-- End Buttons -->
              </div>

              <div class="col-md-4">
                <!-- Image -->
                <div class="card">
                  <div class="card-body">
                    <div class="form-group">
                      <label class="control-label"
                        >{{ $t("projects.image") }}
                        <button
                          v-if="row.preview"
                          type="button"
                          class="btn btn-danger btn-sm btn-rounded btn-bold"
                          style="margin-top: 5px"
                          @click="
                            row.preview = '';
                            row.image = '';
                          "
                        >
                          {{ $t("projects.remove") }}
                        </button>
                      </label>
                      <p v-if="row.preview">
                        <img
                          :src="row.preview"
                          style="max-width: 100%;height: 200px"
                        />
                      </p>
                      <input
                        type="file"
                        class="form-control border-0 p-l-0"
                        ref="myDropify"
                        accept="image/*"
                        v-on:change="onImageChange"
                      />
                    </div>
                  </div>
                </div>
                <!-- End Image -->

                <!-- Role -->
                <div class="card" v-if="auth.role == 'root'">
                  <div class="card-body">
                    <div
                      v-if="roleLoading"
                      class="form-group text-center ml-50"
                    >
                      <div class="loader loader-lg ui-mtop-5"></div>
                    </div>
                    <div v-if="!roleLoading" class="form-group">
                      <label class="control-label">{{
                        $t("projects.role")
                      }}</label>
                      <select
                        class="form-control text-capitalize"
                        v-model="row.role"
                      >
                        <option
                          v-for="(role, index) in roles"
                          :key="index"
                          :value="role.name"
                          >{{ role.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <!-- End Role -->

                <!-- Country -->
                <div class="card" v-if="auth.role == 'root'">
                  <div class="card-body">
                    <div class="form-group">
                      <label class="control-label">{{
                        $t("projects.country")
                      }}</label>
                      <div v-if="countryLoading" class="text-center">
                        <div class="loader loader-xs"></div>
                      </div>
                      <select
                        v-if="!countryLoading"
                        class="form-control"
                        v-model="row.country_id"
                      >
                        <option value="">{{
                          $t("projects.select_country")
                        }}</option>
                        <option
                          v-for="(con, index) in countries"
                          :key="index"
                          :value="con.id"
                        >
                          {{ con.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- End Country -->

                <!-- Status -->
                <div class="card" v-if="auth.role == 'root'">
                  <div class="card-body">
                    <div class="form-group">
                      <div class="m-b-10">
                        <label class="control-label">{{
                          $t("projects.status")
                        }}</label>
                      </div>
                      <div class="radio d-inline m-r-15">
                        <input
                          id="statusRadio1"
                          type="radio"
                          v-model="row.status"
                          value="1"
                          :checked="row.status"
                        />
                        <label for="statusRadio1">
                          {{ $t("projects.active") }}
                        </label>
                      </div>
                      <div class="radio d-inline m-r-15">
                        <input
                          id="statusRadio2"
                          type="radio"
                          v-model="row.status"
                          value="0"
                          :checked="row.status"
                        />
                        <label for="statusRadio2">
                          {{ $t("projects.inactive") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Status -->
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import QuickView from "../layouts/QuickView.vue";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "Edit",
  components: {
    Header,
    QuickView,
    Footer,
  },
  mounted() {},
  data() {
    return {
      //
      auth: {
        access_token: "",
        role: "",
      },
      row: {
        // row
        first_name: "",
        last_name: "",
        ccode: "",
        mobile: "",
        email: "",
        password: "",
        role: "",

        country_id: "",

        // image
        preview: "",
        image: "",

        // status & visiblity
        status: 1,
      },
      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: [window.editor_plugins],
        toolbar: window.editor_toolbar,
      },

      countryLoading: true,
      countries: [],

      pgLoading: true,
      btnLoading: false,

      roles: [],
      roleLoading: true,
      isClicked: true,
      locale: "ar",
      refs: "staffs",
    };
  },
  computed: {},
  created() {
    // access_token & Role
    if (localStorage.getItem("locale")) {
      this.locale = localStorage.getItem("locale");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }

    //
    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.$axios(options)
        .then((res) => {
          this.pgLoading = false;

          // row
          this.row.first_name = res.data.row.first_name;
          this.row.last_name = res.data.row.last_name;
          this.row.email = res.data.row.email;
          this.row.ccode = res.data.row.ccode;
          this.row.mobile = res.data.row.mobile;

          // Role
          (this.row.country_id = res.data.row.country
            ? res.data.row.country.id
            : ""),
            (this.row.role = res.data.row.role);

          // image
          this.row.preview = res.data.row.image ? res.data.row.image.url : null;
          this.row.image = res.data.row.image ? res.data.row.image.url : null;

          // status & visiblity
          this.row.status = res.data.row.status;

          if (this.auth.role == "root") {
            this.fetchRoles();
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 403) {
            this.removeLocalStorage();
            this.$router.push({ name: "forbidden" });
          } else {
            this.pgLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // fetch Roles
    fetchRoles() {
      this.roleLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/roles",
        method: "GET",
        data: {},
      };
      this.$axios(options)
        .then((res) => {
          this.roleLoading = false;
          this.roles = res.data.rows;

          this.fetchCountries();
        })
        .catch(() => {})
        .finally(() => {});
    },

    fetchCountries() {
      this.countryLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/countries",
        method: "GET",
        data: {},
        params: {
          locale: this.locale,
          status: true,
          paginate: 100,
        },
      };
      this.$axios(options)
        .then((res) => {
          this.countryLoading = false;
          this.countries = res.data.rows;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // edit Row
    editRow() {
      this.isClicked = false; // disabled alert msg
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/" + this.$route.params.id,
        method: "PUT",
        data: {
          // row
          first_name: this.row.first_name,
          last_name: this.row.last_name,
          email: this.row.email,
          mobile: this.row.mobile,
          password: this.row.password,
          ccode: this.row.ccode,

          country_id: this.row.country_id,

          // role
          role: this.row.role,

          // image
          image: this.row.image,

          // status & visibility
          status: this.row.status,
        },
      };

      this.$axios(options)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "",
            message: "تم التعديل بنجاح",
          });
          this.$router.push({ name: this.refs });
          this.isClicked = true; // enabled alert msg
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 403) {
            this.removeLocalStorage();
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
          this.isClicked = true; // enabled alert msg
        })
        .finally(() => {});
    },

    // remove LocalStorage
    removeLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("avatar");
      localStorage.removeItem("role");
    },

    // Upload image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    cancel() {
      if (confirm("هل تريد المغادرة ؟")) {
        this.isClicked = false;
        this.$router.push({ name: this.refs });
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.isClicked) {
      if (confirm("هل تريد المغادرة ؟")) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped=""></style>
