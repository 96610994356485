<template>
    <div class="">
        
        <!-- Navigation START -->
        <div class="side-nav expand-lg">
            <div class="side-nav-inner">

                <!-- <ul v-if="pgLoading" class="side-nav-menu scrollable">
                    <li class="side-nav-header m-v-15"></li>
                    <li v-for="(nav, index) in 10" 
                        :key="index" 
                        class="nav-item navy webkit-animation webkit-100-50 m-v-15"></li>
                </ul> -->

                <ul v-if="!pgLoading" class="side-nav-menu scrollable">

                    <!-- <li class="side-nav-header">
                        <span v-html="$t('nav.navigation')"></span>
                    </li> -->

                    <li class="nav-item"
                        @click="clearFolded()" 
                        :class="(this.$route.path == '/applications') 
                                ? 'active' : '' ">
                        <router-link :to="{ name: 'applications' }">
                            <span class="icon-holder">
                                <i class="mdi mdi-briefcase-check"></i>
                            </span>
                            <span class="title" v-html="$t('app.applications')"></span>
                        </router-link>
                    </li>

                    <li class="nav-item"
                        @click="clearFolded()" 
                        v-for="(nav, index) in navigations"
                        :key="index"

                        :class="[ (isPath('/'+nav.slug)) 
                                   ? 'active' 
                                   : '',
                                   nav.authority 
                                   ? '' 
                                   : 'hidden' ]">
                        <router-link :to="{ name: nav.slug }" >
                            <span class="icon-holder">
                                <i :class="nav.icon"></i>
                            </span>
                            <span class="title" v-html="nav.name"></span>
                        </router-link>
                    </li>
                    <p><br/><br/></p>
                        
                </ul>
            </div>
        </div>
        <!-- Navigation END -->

    </div>
</template>

<script>
    export default {
        name: 'Navigation',
        data(){
            return {
                auth: {
                    role: '',
                    access_token: '',
                },
                locale: 'ar',
                pgLoading: true,
                navigations: [],
                path: '/',
              //
            }
        },
        mounted() {},
        created() {

            // AccessToken & Role
            if(localStorage.getItem('role')) {
                this.auth.role = localStorage.getItem('role');
            }
            if(localStorage.getItem('access_token')) {
                this.auth.access_token = localStorage.getItem('access_token');
            }

            if(localStorage.getItem('locale')) {
                this.locale = localStorage.getItem('locale');
            }

            // Path
            if(this.$route.path) {
                this.path = this.$route.path;
            }

            this.fetchData();
        },
        methods: {

            fetchData(){
                this.pgLoading = true;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const options = {
                    url: window.baseURL+'/applications',
                    method: 'GET',
                    data: {},
                    params: {
                        locale: this.locale,
                        status: 'active',
                        order: 'DESC',
                        paginate: 100
                    },
                }
                this.axios(options)
                .then(res => {
                    this.pgLoading = false;
                    this.navigations = res.data.rows;
                })
                .catch(() => { })
                .finally(() => { })
            },


            clearFolded() {
                let app = document.querySelectorAll("div.app");
                let appx = app[app.length-1];
                if(appx.classList.contains('side-nav-folded')) {
                    appx.classList.remove('side-nav-folded');
                }

                if(document.querySelectorAll("div.side-nav-backdrop")) {
                    let app2 = document.querySelectorAll("div.side-nav-backdrop");
                    let appx2 = app2[app2.length-1];
                    if(appx2.classList.contains('side-nav-backdrop')) {
                        appx2.classList.remove('side-nav-backdrop');
                    }
                } else {
                    //
                }
            },


            isPath(slug) {
                if(this.$route.path.includes(slug)) {
                    return true;
                } else {
                    return false;
                }
            }
            
        },
    }
</script>
