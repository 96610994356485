<template>
    <div class="">
  
      <Header></Header>
  
      <div class="page-container">
        <QuickView></QuickView>
  
        <!-- Content Wrapper START -->
        <div class="main-content">
          <div class="container-fluid">
  
            <!-- Header -->
            <div class="page-header">
  
              <!-- Title -->
              <h2 class="header-title text-capitalize"
                  :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                <i class="mdi mdi-image"></i>
                {{ $t('projects.donation_vouchers') }}
              </h2>
              <router-link :to="{ name: refs }"
                           class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
              </router-link>
  
              <!-- Role -->
              <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                {{ auth.role }}
              </label>
              <!-- End Role -->
  
              <div class="clearfix"></div>
  
              <!-- Breadcrumb -->
              <nav class="breadcrumb breadcrumb-dash"
                   :class="(locale == 'ar') ? 'dir-rtl' : ''">
                <router-link :to="{ name: 'dashboard' }"
                             class="breadcrumb-item">
                  <i class="ti-home p-r-5"></i>
                  {{ $t('projects.dashboard') }}
                </router-link>
                <router-link :to="{ name: refs }"
                             class="breadcrumb-item text-capitalize">{{ $t('projects.donation_vouchers') }}
                </router-link>
                <span class="breadcrumb-item active">{{ $t('projects.edit') }}</span>
              </nav>
              <!-- End Breadcrumb -->
  
            </div>
            <!-- End Header -->
  
            <div v-if="pgLoading" class="row h-100">
              <div class="container text-center">
                <div class="loader loader-lg ui-mtop-15"></div>
              </div>
            </div>
  
            <form v-if="!pgLoading" @submit.prevent="editRow" enctype="multipart/form-data">
              <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">
  
                <div class="col-md-8">
  
                  <!-- Content -->
                  <div class="card">
                    <div class="row">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-11 ml-auto mr-auto">
  
                            <!-- Languages -->
                            <div class="tab-danger">
                              <div class="tab-content">
  
                                <div role="tabpanel"
                                     class="tab-pane fade active show"
                                     id="tab-arabic">
  
                                  <div class=" p-v-20">
  
                                    <div class="form-group">
                                      <label class="control-label">{{ $t('projects.donation_voucher_value') }}</label>
                                      <select class="form-control" v-model="row.price">
                                        <option value="10">د.ك10.000</option>
                                        <option value="25">د.ك25.000</option>
                                        <option value="50">د.ك50.000</option>
                                        <option value="100">د.ك100.000</option>
                                        <option value="250">د.ك250.000</option>
                                        <option value="500">د.ك500.000</option>
                                        <option value="1000">د.ك1,000.000</option>
                                      </select>
                                    </div>
  
                                    <div class="form-group">
                                      <label class="control-label">{{ $t('projects.from_name') }}</label>
                                      <input type="text"
                                             class="form-control"
                                             v-model="row.from_name">
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label">{{ $t('projects.message') }}</label>
                                      <textarea
                                          class="form-control"
                                          rows="10"
                                          v-model="row.message">
                                                          </textarea>
                                    </div>
                                  </div>
  
                                </div>
  
                              </div>
                            </div>
                            <!-- End Languages -->
  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Content -->
  
  
                  <!-- Button -->
                  <div class="form-group">
                    <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                  <span v-if="btnLoading">
                                      <div class="loader loader-xs loader-center"></div>
                                  </span>
                      <span v-if="!btnLoading">{{ $t('projects.update') }}</span>
                    </button>
                    <button type="button" @click="cancel" :disabled="btnLoading"
                            class="btn btn-default btn-size p-10">
                      {{ $t('projects.cancel') }}
                    </button>
                  </div>
                  <!-- End Buttons -->
  
                </div>
  
              </div>
            </form>
  
  
          </div>
        </div>
  
        <Footer></Footer>
      </div>
  
    </div>
  </template>
  
  <script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';
  
  export default {
    name: 'Edit',
    components: {
      Header,
      QuickView,
      Footer,
    },
    mounted() {
    },
    data() {
      return {
        //
        auth: {
          access_token: '',
          role: '',
        },
        row: {
          // row
          price: '',
          from_name: '',
          message: '',
        },
        pgLoading: true,
        btnLoading: false,
        isClicked: true,
        locale: 'ar',
        refs: 'donation-vouchers',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if (localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if (localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if (localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }
  
      //
      this.fetchRow();
    },
    methods: {
  
      // fetch Row
      fetchRow() {
        this.pgLoading = true;
        this.axios.defaults.headers.common = {
          'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
          'Authorization': `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
          method: 'GET',
          data: {}
        }
        this.$axios(options)
            .then(res => {
              this.pgLoading = false;
  
              this.row.price = res.data.row.price;
              this.row.from_name = res.data.row.from_name;
              this.row.message = res.data.row.message;
            })
            .catch(err => {
              // 403 Forbidden
              if (err.response && err.response.status == 403) {
                this.removeLocalStorage();
                this.$router.push({name: 'forbidden'});
              } else {
                this.pgLoading = false;
                iziToast.warning({
                  icon: 'ti-alert',
                  title: '',
                  message: (err.response) ? err.response.data.message : '' + err
                });
              }
            })
            .finally(() => {
            });
      },
  
      // edit Row
      editRow() {
        this.isClicked = false; // disabled alert msg
        this.btnLoading = true;
        this.axios.defaults.headers.common = {
          'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
          'Authorization': `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
          method: 'PUT',
          data: {
            message: this.row.message,
            price: this.row.price,
            from_name: this.row.from_name,
          }
        }
  
        this.$axios(options)
            .then(() => {
              this.btnLoading = false;
              iziToast.success({
                icon: 'ti-check',
                title: '',
                message: 'تم التعديل بنجاح',
              });
              this.$router.push({name: this.refs});
              this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
              // 403 Forbidden
              if (err.response && err.response.status == 403) {
                this.removeLocalStorage();
                this.$router.push({name: 'forbidden'});
  
              } else {
                this.btnLoading = false;
                iziToast.warning({
                  icon: 'ti-alert',
                  title: '',
                  message: (err.response) ? err.response.data.message : '' + err
                });
              }
              this.isClicked = true; // enabled alert msg
            })
            .finally(() => {
            })
      },
  
      // remove LocalStorage
      removeLocalStorage() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        localStorage.removeItem('avatar');
        localStorage.removeItem('role');
      },
  
      cancel() {
        if (confirm('هل تريد المغادرة ؟')) {
          this.$router.push({name: this.refs})
        }
      },
    },
  
    beforeRouteLeave(to, from, next) {
      if (this.isClicked) {
        if (confirm('هل تريد المغادرة ؟')) {
          next();
        }
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped="">
  </style>