<template>
  <!-- <div class="">
        
      <Header></Header>

        <div class="page-container">
          <QuickView></QuickView> -->

  <div class="container">
    <div class="reciept" id="printMe">
      <div class="row header-c" style="margin-top: 10px">
        <div class="col-4 px-5 pt-3">
          <div class="form-group">
            <label class="control">التاريخ : </label>
            <input
              id=""
              name="name"
              type="text"
              disabled
              class="form-control"
              :value="row.date"
            />
          </div>

          <div class="form-group">
            <label class="control">رقم الايصال : </label>
            <input
              id=""
              name="name"
              type="text"
              disabled
              class="form-control"
              :value="row.invoice_id"
            />
          </div>
        </div>

        <div class="col-8 px-5 pt-3">
          <div class="w-100 logo-container">
            <img
              class="media-object logo"
              alt="logo"
              src="/assets/images/logo/charity-logo.png"
              width="280"
            />
          </div>

          <div class="text-right">
            <h4 class="heading">جمعية قلب الاطفال الخيرية</h4>
            <h5 class="heading">الفنيطيس، قطعة ٣ شارع ٣٠٤ قسيمة ٣٤٩، الكويت</h5>
            <h6 class="heading">965-22099903+</h6>
          </div>
        </div>
      </div>
      <br />
      <div class="d-flex justify-content-center">
        <h3
          class="text-center justify-content-center border rounded w-50"
          :style="
            row.donation_status == 'Successed'
              ? 'border-color: green !important;'
              : 'border-color: red !important;'
          "
        >
          حالة التبرع :
          {{ row.donation_status == "Successed" ? "تمت بنجاح" : "فشلت" }}
        </h3>
      </div>

      <br />
      <h3
        class="text-center justify-content-center"
        style="text-decoration: underline; text-decoration-color: #6c757d"
      >
        تفاصيل الطلب
      </h3>

      <div class="invoice-data px-5">
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="form-group">
              <label class="control" for="">رقم الطلب : </label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.cart_id"
              />
            </div>
            <div class="form-group">
              <label class="control" for="">رقم المرجع :</label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.invoiceKey"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="control" for="">اسم العميل : </label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.name"
              />
            </div>
            <div class="form-group">
              <label class="control" for="">اجمالي التبرع :</label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.amount"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="form-group">
              <label class="col-4 control" for="">رقم الهاتف : </label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.mobile"
              />
            </div>
            <div class="form-group">
              <label class="col-4 control" for="">البريد الاكتروني : </label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.email_address"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="col-4 control" for="">طريقة الدفع : </label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="
                  row.payment_method == 1
                    ? 'KNET'
                    : row.payment_method == 8
                    ? 'Debit Cards UAE'
                    : row.payment_method == 9
                    ? 'Qatar Debit Card'
                    : row.payment_method == 2
                    ? 'VISA / MASTER'
                    : ''
                "
              />
            </div>
            <div class="form-group">
              <label class="col-4 control" for="">ملاحظات العميل : </label>
              <input
                id=""
                name="name"
                type="text"
                disabled
                class="form-control"
                :value="row.notes"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row">
                <div class="col-6">
                    <div class="form-group">
                    <label class="col-6 control" for="">Section :</label>
                    <div class="col-6" style="padding-left:20px;">
                        <input id="" name="name" type="text" placeholder="Section" class="form-control" >
                    </div>
                    </div>
                    </div>
                    <div class="col-6">
                    <div class="form-group">
                    <label class="col-3 control" for="">Batch :</label>
                    <div class="col-9">
                        <input id="" name="name" type="text" placeholder="Batch" class="form-control">
                    </div>
                    </div>
                    </div>
                    </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                    <label class="col-3 control" for="name">Recieve Amount :</label>
                    <div class="col-9">
                        <input id="name" name="name" type="text" placeholder="Amount" class="form-control">
                    </div>
                    </div>
                    </div>
                    </div>
                    <br/>
                    <div class="row">
                <div class="col-6">
                    <div class="form-group">
                    <label class="col-3 control" for="name">Cash :</label>
                    <div class="col-9">
                        <input id="checkbox2" type="checkbox" checked="" class="form-control">
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-3 control" for="name">Cheque :</label>
                    <div class="col-9">
                        <input id="checkbox2" type="checkbox" checked="" class="form-control">
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-3 control">Bank Transfer :</label>
                    <div class="col-9">
                        <input id="checkbox2" type="checkbox" checked="" class="form-control">
                    </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                    <label class="col-sm-3 control">Bank Name :</label>
                    <div class="col-sm-9">
                        <input id="" name="name" type="text" placeholder="State Bank of India" class="form-control">
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-sm-3 control" >Cheque No :</label>
                    <div class="col-sm-9">
                        <input id="" name="name" type="text" placeholder="Cheque no" class="form-control">
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-sm-3 control" >Date :</label>
                    <div class="col-sm-9">
                        <input id="" name="name" type="text" placeholder="Date" class="form-control">
                    </div>
                    </div>
                </div>
                    </div>
                    <br/>
                    <div class="row">
                <div class="col-12">
                    <div class="form-group">
                    <label class="col-3 control" for="name">Recieved By :</label>
                    <div class="col-9">
                        <input id="name" name="name" type="text" placeholder="Authorised Person" class="form-control">
                    </div>
                    </div>
                </div>
                </div> -->
      <h3
        class="text-center justify-content-center"
        style="text-decoration: underline; text-decoration-color: #6c757d"
      >
        تفاصيل التبرع
      </h3>

      <div class="row justify-content-center">
        <div class="col-9">
          <div class="table-responsive">
            <table
              class="table table-striped table-borderless border-0 border-b-2 brc-default-l1 text-center justify-content-center"
            >
              <thead class="bg-none bgc-default-tp1">
                <tr class="text-white">
                  <th class="opacity-2">#</th>
                  <th>{{ $t("projects.image") }}</th>
                  <th>{{ $t("projects.name") }}</th>
                  <th>{{ $t("projects.amount") }}</th>
                  <th width="140">{{ $t("projects.date") }}</th>
                </tr>
              </thead>

              <tbody class="text-95 text-secondary-d3">
                <!-- <tr></tr> -->
                <tr v-for="(cart, index) in row.orders" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      v-if="cart.image"
                      :src="cart.image"
                      style="width: 100px; height: 100px"
                    />
                    <img
                      v-if="!cart.image"
                      src="/assets/images/logo/charity-logo.png"
                      style="max-width: 200px"
                    />
                  </td>
                  <td>
                    <span v-if="cart.name">{{ cart.name }}</span>
                    <span v-if="cart.type"> {{ cart.type.name }}</span>
                  </td>
                  <td class="text-95">
                    {{ cart.amount }}
                  </td>
                  <td class="text-secondary-d2">{{ cart.exp_timestamp }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr />
        </div>
      </div>

      <div class="row" style="margin-top: 80px">
        <div class="col-9"></div>
      </div>
    </div>

    <button class="btn btn-dark btn-size" v-print="printObj">
      <span>{{ $t("app.print") }}</span>
    </button>
  </div>

  <!-- </div>
</div> -->

  <!-- <Footer></Footer>
        </div>

  </div> -->
</template>

<script>
//   import Header from '../layouts/Header.vue';
//   import QuickView from '../layouts/QuickView.vue';
//   import Footer from '../layouts/Footer.vue';
import iziToast from "izitoast";

export default {
  name: "Edit",
  components: {},
  mounted() {},
  data() {
    return {
      //
      auth: {
        access_token: "",
        role: "",
      },
      row: {
        // row
        name: "",
        email: "",
        mobile: "",
        image: "",
        notes: "",

        orders: [],
      },

      pgLoading: true,
      btnLoading: false,
      locale: "ar",
      ref: "donations",

      printObj: {
        id: "#printMe",
        extraCss: "/public/assets/css/receipt.css",
      },
    };
  },
  computed: {},
  created() {
    // access_token & Role
    if (localStorage.getItem("locale")) {
      this.locale = localStorage.getItem("locale");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }

    //
    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.ref + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.$axios(options)
        .then((res) => {
          this.pgLoading = false;

          // row
          this.row = res.data.row;
          // this.row.name   = (res.data.row.user)
          //                     ? res.data.row.user.first_name+' '+res.data.row.user.last_name : '';
          // this.row.email  = (res.data.row.user) ? res.data.row.user.email : '';
          // this.row.mobile = (res.data.row.user) ? res.data.row.user.mobile : '';
          this.row.name = res.data.row.name ? res.data.row.name : "";
          this.row.email = res.data.row.email_address
            ? res.data.row.email_address
            : "";
          this.row.mobile = res.data.row.mobile ? res.data.row.mobile : "";
          this.row.notes = res.data.row.notes ? res.data.row.notes : "";
          this.row.image = res.data.row.user ? res.data.row.user.image.url : "";

          this.row.orders = res.data.row.data;
          console.log("orderdate", this.row.orders[0]);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 403) {
            this.removeLocalStorage();
            this.$router.push({ name: "forbidden" });
          } else {
            this.pgLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // remove LocalStorage
    removeLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("avatar");
      localStorage.removeItem("role");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
@media print {
  * {
    direction: rtl !important;
    text-align: start;
  }
  .header-c {
    flex-direction: row-reverse;
  }
  input {
    width: 100% !important;
  }
  .table > thead > tr > th {
    color: #000000 !important;
    border-bottom: 1px solid #000000 !important;
  }
}

* {
  direction: rtl !important;
}
.header-c {
  flex-direction: row-reverse;
}

input[type="text"] {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px dotted #6c757d !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
}

.heading {
  color: #6c757d;
}

.control {
  padding-top: 7px;
}
label {
  margin-bottom: 5px;
}
.reciept {
  border-top: 5px solid #6c757d;
  -webkit-box-shadow: 0px 5px 21px -2px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 5px 21px -2px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 5px 21px -2px rgba(0, 0, 0, 0.47);
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-print-color-adjust: exact;
}

body {
  margin-top: 20px;
  color: #484b51;
}
.text-secondary-d1 {
  color: #728299 !important;
}
.page-header {
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  border-bottom: 1px dotted #e2e2e2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
}
.brc-default-l1 {
  border-color: #dce9f0 !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-grey-m2 {
  color: #ffffff !important;
}

.text-success-m2 {
  color: #86bd68 !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-110 {
  font-size: 110% !important;
}
.text-blue {
  color: #478fcc !important;
}
.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}
.bgc-default-tp1 {
  background-color: #515365 !important;
}
.bgc-default-l4,
.bgc-h-default-l4:hover {
  background-color: #f3f8fa !important;
}
.page-header .page-tools {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}
.w-2 {
  width: 1rem;
}

.text-120 {
  font-size: 120% !important;
}
.text-primary-m1 {
  color: #4087d4 !important;
}

.text-danger-m1 {
  color: #dd4949 !important;
}
.text-blue-m2 {
  color: #68a3d5 !important;
}
.text-150 {
  font-size: 150% !important;
}
.text-60 {
  font-size: 60% !important;
}

.align-bottom {
  vertical-align: bottom !important;
}
.table > thead > tr > th {
  color: #ffffff;
  border-bottom: 1px solid #e9eaec;
}
</style>