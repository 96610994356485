<template>
  <div class="">
    <div class="layout" style="background: url('/assets/images/bg/login.jpg'); background-size: 100% 100%;">
      <div class="container">
        <div class="row full-height align-items-center">
          <div class="col-md-5 mx-auto">
            <div class="card">
              <div class="card-body">
                <div class="p-15">
                  <div class="m-b-30 text-center">
                    <router-link :to="{ name: 'login' }">
                      <img
                        class="img-responsive inline-block"
                        src="/assets/images/logo/children-charity-logo.png"
                        style="width: 150px"
                        alt="charity"
                      />
                    </router-link>
                  </div>

                  <form @submit.prevent="forget">
                    <div class="form-group">
                      <label class="control-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="row.email"
                        required=""
                      />
                    </div>

                    <div class="m-t-30 text-left">
                      <button class="btn btn-dark btn-size f16">
                        <span v-if="btnLoading">
                          <div class="loader loader-xs loader-center"></div>
                        </span>
                        <span v-if="!btnLoading">Send</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "forget",
  data() {
    return {
      row: {
        email: "",
      },
      btnLoading: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    forget() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL + "/forget/password",
        method: "POST",
        data: {
          email: this.row.email,
        },
      };
      this.$axios(options)
        .then((res) => {
          this.btnLoading = false;

          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("name", res.data.username);
          localStorage.setItem("avatar", res.data.avatar);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("id", res.data.user_id);

          // Set default Navigation
          // localStorage.setItem('nav_roles', 'show');

          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          this.btnLoading = false;
          this.row.password = "";
          iziToast.warning({
            icon: "ti-alert",
            title: "",
            message: "Unauthorized",
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>